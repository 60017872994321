import React from "react";
import parse, {
  domToReact,
  HTMLReactParserOptions,
  DOMNode,
} from "html-react-parser";
import Typography from "@mui/material/Typography";

interface HtmlContentProps {
  html: string;
  sx?: React.CSSProperties;
}

function convertEmailsToLinks(htmlString: string) {
  const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
  return htmlString.replace(
    emailRegex,
    '<nobr><a href="mailto:$1">$1</a></nobr>'
  );
}

function removeBreaks(htmlString: string) {
  return htmlString.replace(/<br\s*\/?>/gi, "");
}

function normalizeEmptyParagraphs(htmlString: string) {
  // Tar bort dubbla toma p-taggar. Kvar är <p><br /></p>:
  return htmlString.replace(/(<p>\s*<\/p>\s*)+/gi, "<p><br /></p>");
}

function removeLeadingAndTrailingEmptyParagraph(htmlString: string) {
  return htmlString
    .replace(/^<p>\s*<br\s*\/?>\s*<\/p>\s*/i, "") // Tar bort första <p><br /></p>
    .replace(/\s*<p>\s*<br\s*\/?>\s*<\/p>$/i, ""); // Tar bort sista <p><br /></p>
}

const HtmlContent: React.FC<HtmlContentProps> = ({ html, sx }) => {
  const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      if (domNode.type === "tag" && "name" in domNode) {
        const { name, children } = domNode;
        const childContent = children
          ? domToReact(children as DOMNode[], options)
          : null;

        switch (name) {
          case "p":
            return (
              <Typography
                variant="body1"
                gutterBottom
                sx={{ ...sx, margin: "0.5em" }}
              >
                {childContent}
              </Typography>
            );
          case "b":
          case "strong":
            return (
              <Typography component="span" style={{ fontWeight: "bold" }}>
                {childContent}
              </Typography>
            );
          case "i":
          case "em":
            return (
              <Typography component="span" style={{ fontStyle: "italic" }}>
                {childContent}
              </Typography>
            );
          default:
            return undefined; // Bevara taggen som standard
        }
      }
    },
  };

  return (
    <>
      {parse(
        removeLeadingAndTrailingEmptyParagraph(
          normalizeEmptyParagraphs(removeBreaks(convertEmailsToLinks(html)))
        ),
        options
      )}
    </>
  );
};

export default HtmlContent;
