import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { RootState } from "../store";
import { isAdmin } from "../utils/auth";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  CircularProgress,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import AdminMembers from "./AdminMembers";

const Admin: React.FC = () => {
  const email = useSelector((state: RootState) => state.auth.email);
  const loading = useSelector((state: RootState) => state.auth.loading);
  const navigate = useNavigate();

  // Loading state to handle race condition.
  // We do not want to be redirected away from the page before the JwtHandler
  // has had a chance to check the token and update the auth state.
  if (loading) {
    return (
      <>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Laddar...</Typography>
      </>
    );
  }

  // Om användaren inte är admin, omdirigera till startsidan
  if (!email || !isAdmin(email)) {
    return <Navigate to="/" />;
  }

  return (
    <Routes>
      <Route path="/" element={<AdminDashboard />} />
      <Route path="/medlemmar" element={<AdminMembers />} />
    </Routes>
  );
};

export default Admin;
