import React, { useState, useRef, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useEditorContext } from "../EditorContext";

interface ZoomableImageProps {
  src: string;
  aspectRatio: number;
  zoom: number; // 0 => minimal skala
  focalX: number; // [0..1]
  focalY: number; // [0..1]
  onFocalChange?: (x: number, y: number) => void; // Callback när vi ändrar focal
  sx?: React.CSSProperties;
  borderRadius?: number;
}

const ZoomableImage: React.FC<ZoomableImageProps> = ({
  src,
  aspectRatio,
  zoom,
  focalX,
  focalY,
  onFocalChange,
  sx = {},
  borderRadius,
}) => {
  const { setCoverActive } = useEditorContext();

  // Lagra originaldimensioner:
  const [imageSize, setImageSize] = useState<{ w: number; h: number } | null>(
    null
  );

  // Lagra container-storlek
  const [containerSize, setContainerSize] = useState<{ w: number; h: number }>({
    w: 0,
    h: 0,
  });
  const containerRef = useRef<HTMLDivElement | null>(null);

  // Ladda bilden
  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImageSize({ w: img.width, h: img.height });
    };
  }, [src]);

  // Observera storlek via ResizeObserver
  useEffect(() => {
    if (!containerRef.current) return;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setContainerSize({ w: width, h: height });
      }
    });
    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [src, imageSize]);

  const dragging = useRef({
    isActive: false,
    startX: 0,
    startY: 0,
    startFocalX: 0,
    startFocalY: 0,
  });

  // Om bilden inte är laddad
  if (!imageSize) {
    return (
      <Box
        sx={{
          width: "100%",
          aspectRatio,
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const { w: containerWidth, h: containerHeight } = containerSize;
  if (containerWidth === 0 || containerHeight === 0) {
    return (
      <Box
        ref={containerRef}
        sx={{
          width: "100%",
          aspectRatio,
          position: "relative",
        }}
      />
    );
  }

  // Beräkna minsta skala
  const minScale = Math.max(
    containerWidth / imageSize.w,
    containerHeight / imageSize.h
  );

  // Clampa zoom
  const clampedZoom = Math.max(0, zoom);
  const scale = minScale * (1 + clampedZoom / 100);

  const scaledWidth = imageSize.w * scale;
  const scaledHeight = imageSize.h * scale;

  // Hämta ut x, y från localFocal
  let x = focalX;
  let y = focalY;

  // Clamp [0..1]
  x = Math.min(Math.max(x, 0), 1);
  y = Math.min(Math.max(y, 0), 1);

  // Beräkna offset utifrån x, y
  const translateX = -(scaledWidth - containerWidth) * x;
  const translateY = -(scaledHeight - containerHeight) * y;

  // ====== DRAGGING LOGIC ======
  // Hålla koll på var vi startade (musens första klick, och focal-läge då)

  // När man trycker ner musen / pekaren på bilden
  const handlePointerDown = (e: React.PointerEvent) => {
    setCoverActive(true);
    e.stopPropagation();
    e.preventDefault();
    // Börja lyssna på pointerMove/pointerUp
    (e.target as HTMLElement).setPointerCapture(e.pointerId);

    dragging.current.isActive = true;
    dragging.current.startX = e.clientX;
    dragging.current.startY = e.clientY;
    dragging.current.startFocalX = x;
    dragging.current.startFocalY = y;
  };

  // Under drag
  const handlePointerMove = (e: React.PointerEvent) => {
    if (!dragging.current.isActive) return;

    e.stopPropagation();
    e.preventDefault();

    // Hur långt har vi dragit från start?
    const deltaX = e.clientX - dragging.current.startX;
    const deltaY = e.clientY - dragging.current.startY;

    // Räkna ut ny fokalpunkt
    // Notera att: translateX = -(scaledWidth - containerWidth) * focalX
    // => focalX = -translateX / (scaledWidth - containerWidth)
    // Men vi beräknar den relativa förändringen:
    //   focalX = startFocalX - (deltaX / (scaledWidth - containerWidth))
    //   (minus eftersom drar vi åt höger => deltaX>0 => vi ska öka focalX)
    const newFocalX =
      dragging.current.startFocalX - deltaX / (scaledWidth - containerWidth);
    const newFocalY =
      dragging.current.startFocalY - deltaY / (scaledHeight - containerHeight);

    // Clampa och uppdatera state
    const clampedX = Math.min(Math.max(newFocalX, 0), 1);
    const clampedY = Math.min(Math.max(newFocalY, 0), 1);

    // Om föräldern vill veta nya värden:
    if (onFocalChange && !isNaN(clampedX) && !isNaN(clampedY)) {
      onFocalChange(clampedX, clampedY);
    }
  };

  // När vi släpper musen/pekaren
  const handlePointerUp = (e: React.PointerEvent) => {
    setCoverActive(false);

    e.stopPropagation();
    e.preventDefault();
    dragging.current.isActive = false;
    (e.target as HTMLElement).releasePointerCapture(e.pointerId);
  };

  // =========== RENDER ===========
  return (
    <Box
      ref={containerRef}
      sx={{
        width: "100%",
        aspectRatio,
        position: "relative",
        overflow: "hidden",
        ...(borderRadius ? { borderRadius } : {}),
      }}
    >
      <Box
        component="img"
        src={src}
        alt=""
        onPointerDown={(e) => onFocalChange && handlePointerDown(e)}
        onPointerMove={(e) => onFocalChange && handlePointerMove(e)}
        onPointerUp={(e) => onFocalChange && handlePointerUp(e)}
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          cursor: onFocalChange
            ? dragging.current.isActive
              ? "grabbing"
              : "grab"
            : "default", // Indikerar att man kan dra
          // Du kan lägga 'cursor: grabbing' dynamiskt när drag = true
          width: scaledWidth,
          height: scaledHeight,
          transform: `translate(${translateX}px, ${translateY}px)`,
          transformOrigin: "top left",
          userSelect: "none", // förhindra textmarkering
        }}
      />
    </Box>
  );
};

export default ZoomableImage;
