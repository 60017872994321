import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SwishQRCode from "../components/SwishQRCode";
import { paymentLinks } from "../utils/swish";
import { getSubscriptionType } from "../utils/config";

const ConfirmationPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const applicationData = location.state as {
    membershipType: string;
    name: string;
    email: string;
    address: string;
    city: string;
    postalCode: string;
    country?: string;
    isOrganization?: boolean;
  };

  if (!applicationData) {
    return (
      <Box
        sx={{
          maxWidth: 600,
          margin: "0 auto",
          padding: 4,
          textAlign: "center",
        }}
      >
        <Typography variant="h6" color="error" gutterBottom>
          Ingen ansökan att visa.
        </Typography>
        <Button variant="contained" onClick={() => navigate("/")}>
          Tillbaka till ansökan
        </Button>
      </Box>
    );
  }

  const selectedPayment = getSubscriptionType(
    applicationData.membershipType
  ) || {
    id: "unknown",
    yearlyFee: 0,
  };

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardHeader
          avatar={
            <CheckCircleOutlineIcon color="success" sx={{ fontSize: 40 }} />
          }
          title="Tack för din medlemsansökan!"
          titleTypographyProps={{ variant: "h4" }}
          subheader="Din ansökan är nu inskickad."
        />
      </Card>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 4,
        }}
      >
        {/* Vänster box: Sammanfattning av ansökan */}
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Sammanfattning
            </Typography>
            <Typography variant="body2">
              <strong>Namn:</strong> {applicationData.name}
            </Typography>
            <Typography variant="body2">
              <strong>E-post:</strong> {applicationData.email}
            </Typography>
            <Typography variant="body2">
              <strong>Adress:</strong> {applicationData.address}
            </Typography>
            <Typography variant="body2">
              <strong>Postnummer & Postort:</strong>{" "}
              {applicationData.postalCode} {applicationData.city}
            </Typography>
            {applicationData.country && (
              <Typography variant="body2">
                <strong>Land:</strong> {applicationData.country}
              </Typography>
            )}
            <Typography variant="body2">
              <strong>Medlemskap:</strong> {applicationData.membershipType}
            </Typography>
            {applicationData.isOrganization !== undefined && (
              <Typography variant="body2">
                <strong>Prenumerationstyp:</strong>{" "}
                {applicationData.isOrganization
                  ? "Företag/organisation"
                  : "Privatperson"}
              </Typography>
            )}
          </CardContent>
        </Card>

        {/* Höger box: Swish QR-kod och knapp */}
        <Card sx={{ flex: 1, textAlign: "center" }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Betala medlemsavgiften
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Betala in till Fobos bankgirokonto 574-9957 eller via Swish 123
              440 83 16.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
              onClick={() =>
                window.open(
                  `${
                    paymentLinks(
                      selectedPayment?.yearlyFee,
                      selectedPayment?.id
                    ).url
                  }`,
                  "_blank"
                )
              }
            >
              Öppna Swish i mobilen
            </Button>
            <SwishQRCode
              amount={selectedPayment?.yearlyFee}
              message={`Medlemsavgift ${applicationData.name} ${applicationData.postalCode}`.replace(
                /[^a-zA-Z0-9åäöÅÄÖéÉüÜëËáÁàÀóÓ -]/g,
                ""
              )}
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ConfirmationPage;
