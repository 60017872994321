export const paymentLinks = (amount: number, message: string) => {
  const swishNumber = "1234408316";

  const formattedAmount = amount.toFixed(2).replace(".", ",");
  const formattedMessage = encodeURIComponent(message);

  const qrCode = `C${swishNumber};${formattedAmount};${formattedMessage};`;
  const url = `https://app.swish.nu/1/p/sw/?sw=${swishNumber}&amt=${amount}&msg=${formattedMessage}`;

  return { url, qrCode };
};
