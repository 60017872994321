import React, { useState, ReactNode, useEffect, useRef } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { useEditorContext } from "../EditorContext";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface SlideViewProps {
  children: ReactNode;
}

const SlideView: React.FC<SlideViewProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const childArray = React.Children.toArray(children); // Konvertera children till en array

  const {
    editorWidth,
    editorSlideIndex,
    setEditorSlideIndex,
    slideAnimation,
    setSlideAnimation,
  } = useEditorContext();

  const handleNext = () => {
    setEditorSlideIndex((prev) => Math.min(prev + 1, childArray.length - 1));
    setSlideAnimation(true);
  };

  const handlePrev = () => {
    setSlideAnimation(true);
    setEditorSlideIndex((prev) => Math.max(prev - 1, 0));
  };

  useEffect(() => {
    setSlideAnimation(false);
  }, [editorWidth]);

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          display: "block",
          height: "100%",
          position: "relative",
          width: editorWidth,
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        {/* Slides */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            transition: slideAnimation ? "margin-left 0.5s ease" : "none",
            marginLeft: `-${editorSlideIndex * editorWidth}px`,
            width: `${childArray.length * editorWidth}px`,
            height: "100%",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
        >
          {childArray.map((child, index) => (
            <Box
              id={`slide-${index}`}
              key={index}
              sx={{
                width: `${editorWidth}px`,
                flexShrink: 0,
                display: "flex",
                padding: 0,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: editorWidth,
                  padding: "0em 0em 0em 0em",
                }}
              >
                <Box sx={{ width: "100%" }}>{child}</Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default SlideView;
