import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SwishQRCode from "../components/SwishQRCode";
import { paymentLinks } from "../utils/swish";
import { payNowYear } from "../utils/time";
import { config } from "../utils/config";

import { RootState } from "../store";

const MembershipFeePage: React.FC = () => {
  const [inputValue, setInputValue] = useState("");
  const [showQRCode, setShowQRCode] = useState(false);

  const email = useSelector((state: RootState) => state.auth.email);

  const handleSubmit = () => {
    if (inputValue.trim()) {
      setShowQRCode(true);
    } else {
      alert("Fyll i ett medlemsnummer eller en e-postadress.");
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "0 auto", padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Betala medlemsavgift/prenumeration
        <Typography variant="body1" sx={{ mt: 2 }}>
          Är du inte medlem än? Ansök först om medlemskap{" "}
          <Link to="/bli-medlem">här</Link>.
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Medlemsavgifter {payNowYear()}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Avgift</TableCell>
                  <TableCell>Beskrivning</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {config().subscriptionTypes.map((subscriptionType) => (
                  <TableRow>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {subscriptionType.yearlyFee} kr
                    </TableCell>
                    <TableCell>
                      {subscriptionType.description}{" "}
                      {subscriptionType.priceComment
                        ? `(${subscriptionType.priceComment})`
                        : ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Typography>
      <Typography variant="h5" gutterBottom>
        Betala med BankGiro
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        <b>Bankgirokonto:</b> 574-9957
        <br />
        <b>Ange:</b> Namn och adress
      </Typography>
      <Typography variant="h5" gutterBottom>
        Betala med Swish
      </Typography>
      {!email && !showQRCode && (
        <>
          <Typography variant="body1" gutterBottom>
            Ange ditt medlemsnummer eller e-postadress för att betala
            medlemsavgiften för {payNowYear()}.
          </Typography>

          <TextField
            label="Medlemsnummer eller e-postadress"
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            Betala
          </Button>
        </>
      )}
      {(showQRCode || email) && (
        <>
          <Typography variant="body1" gutterBottom>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
              onClick={() =>
                window.open(
                  `${paymentLinks(250, `Medlemsavgift ${inputValue}`).url}`,
                  "_blank"
                )
              }
            >
              Öppna Swish i mobilen
            </Button>
          </Typography>

          <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
            <b>Swishnummer:</b> 123 440 83 16
            <br />
            <b>Ange:</b> Medlemsavgift {email || inputValue}
          </Typography>
          <SwishQRCode
            amount={250}
            message={`Medlemsavgift ${email || inputValue}`}
          />
        </>
      )}
    </Box>
  );
};

export default MembershipFeePage;
