import BreadcrumbBlock from "./breadcrumbs";
import TitleBlock from "./title";
import BodyTextBlock from "./bodyText";
import ImageBlock from "./image";
import IngressBlock from "./ingress";
import CtaBlock from "./cta";
import TeaserBlock from "./teaser";
import QuoteBlock from "./quote";
import YoutubeBlock from "./youtube";
import ExternalLinkBlock from "./extlink";
import productBlock from "./product";

const blocks = [
  TitleBlock,
  IngressBlock,
  BodyTextBlock,
  QuoteBlock,
  ImageBlock,
  YoutubeBlock,
  CtaBlock,
  productBlock,
  TeaserBlock,
  ExternalLinkBlock,
];
export default blocks;
