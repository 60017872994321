const adminEmails = [
  "alfred@uix.se",
  "kansli@fobo.se",
  "odlaren@kagus.nu",
  "layout@kagus.nu",
];

export function isAdmin(email: string | null): boolean {
  if (!email) return false; // Om e-post är null, inte admin
  return adminEmails.includes(email.toLowerCase()); // Kontrollera mot listan, case-insensitiv
}
