import React, { useMemo, useState } from "react";
import {
  Drawer,
  Box,
  IconButton,
  Button,
  Alert,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEditorContext } from "../EditorContext";
import { useContent } from "../ContentContext";
import { deepEqual } from "../utils/obj";
import { isBlockData } from "../blocks/blockType";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SlideView from "./SlideView";
import SortableList from "./SortableList";
import AddBlockForm from "./AddBlockForm";
import Endpoints from "./Endpoints";
import blockTypes from "../blocks";
import { isAdmin } from "../utils/auth";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import EditTitle from "./EditTitle";
import EditorNavLink from "./EditorNavLink";
import { useLocation } from "react-router-dom";

type ContentEditorProps = {
  children: React.ReactNode;
};

const ContentEditor: React.FC<ContentEditorProps> = ({ children }) => {
  const {
    isOpen,
    setIsOpen,
    editorWidth,
    setEditorWidth,
    editorFocus,
    setEditorFocus,
    workInProgress,
    setWorkInProgress,
    contentClicksDisabled,
    setContentClicksDisabled,
    alert,
    setAlert,
    setSlideAnimation,
    setEditorSlideIndex,
    editorSlideIndex,
  } = useEditorContext();

  const [isResizing, setIsResizing] = useState(false);

  const { blocks, updateBlock } = useContent();

  const closeDrawer = (open: boolean) => {
    setIsOpen(open);
    setWorkInProgress(null);
    setEditorFocus("");
    setContentClicksDisabled(false);
    setAlert(null);
  };

  const cancelChanges = () => {
    setWorkInProgress(null);
    setAlert(null);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsResizing(true);
    document.body.style.userSelect = "none"; // Inaktivera textmarkering
    document.body.style.cursor = "ew-resize"; // Byt till resize-kursor
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isResizing || !isOpen) return;
    setEditorWidth(Math.max(200, Math.min(600, e.clientX)));
  };

  const handleMouseUp = () => {
    setIsResizing(false);
    document.body.style.userSelect = ""; // Återställ textmarkering
    document.body.style.cursor = ""; // Återställ kursor
  };

  const block = useMemo(() => {
    return (
      Array.isArray(blocks) &&
      blocks.find((block) => block.blockId === editorFocus)
    );
  }, [editorFocus, blocks]);

  const { hasChanges, currentBlock } = useMemo(() => {
    const hasChanges =
      workInProgress && block && deepEqual(workInProgress, block) === false;
    const currentBlock = isBlockData(workInProgress) ? workInProgress : block;
    return { hasChanges, currentBlock };
  }, [block, workInProgress]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setAlert(null);
    if (hasChanges && workInProgress !== null) {
      updateBlock(workInProgress);
      setWorkInProgress(null);
    }
  };

  const location = useLocation();

  React.useEffect(() => {
    if (isOpen && editorSlideIndex === 2) {
      setSlideAnimation(true);
      setEditorSlideIndex(1);
    }
  }, [location.pathname]); // Run effect on location change

  React.useEffect(() => {
    if (hasChanges) {
      setContentClicksDisabled(true);
    } else {
      setContentClicksDisabled(false);
    }
  }, [hasChanges, contentClicksDisabled, setContentClicksDisabled]);

  React.useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  const email = useSelector((state: RootState) => state.auth.email);

  if (!isAdmin(email)) {
    return <>{children}</>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Box>
        {alert === "saveOrCancel" && (
          <Alert
            variant="filled"
            severity="warning"
            onClose={() => setAlert(null)}
          >
            You have to save or cancel your changes first.
          </Alert>
        )}
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          height: "100vh",
          overflow: "auto",
        }}
      >
        {/* Sidobar */}
        <Drawer
          anchor="left"
          open={isOpen}
          variant="persistent"
          sx={{
            position: "relative",
            "& .MuiDrawer-paper": {
              position: "absolute",
              width: `${editorWidth}px`,
              boxSizing: "border-box",
              transition: isResizing ? "none" : "width 0.3s ease-in-out",
              background:
                "linear-gradient(to right,rgb(255, 255, 255),rgb(211, 211, 211))",
            },
            margin: 0,
            padding: 0,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              width: "100%",
              marginBottom: "0px",
              paddingBottom: "0px",
              background:
                "linear-gradient(to bottom,rgba(150, 150, 150, 0.99),rgba(207, 207, 207, 0.9))",
              zIndex: 1200,
              borderBottom: "1px solid #666",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
                margin: "0px 0px 0px 0px",
                padding: 0,

                flexGrow: 1,
              }}
            >
              <Box sx={{}}>
                <IconButton
                  sx={{
                    visibility:
                      hasChanges || editorSlideIndex === 0
                        ? "hidden"
                        : "visible",
                  }}
                  onClick={() => {
                    setSlideAnimation(true);
                    setEditorSlideIndex((prev) => Math.max(prev - 1, 0));
                  }}
                  disabled={editorSlideIndex === 0}
                >
                  <EditorNavLink
                    copy={editorSlideIndex === 2 ? "Blocks" : "Pages"}
                    direction="left"
                  />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ margin: "8px 8px 0px 0px" }}>
              {/* Kryssruta */}
              {!hasChanges && (
                <IconButton onClick={() => closeDrawer(false)} sx={{}}>
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          </Box>

          <SlideView>
            <Box
              sx={{
                padding: "4em 1em 0em 1em",
                width: "100%",
              }}
            >
              <Endpoints />
            </Box>
            <Box sx={{ padding: "4em 1em 200px 1em", width: "100%" }}>
              {blocks.length > 0 && (
                <>
                  <EditTitle>Blocks</EditTitle>

                  <Box>
                    <SortableList />
                  </Box>
                </>
              )}
              <EditTitle subtitle={true}>Add block</EditTitle>
              <AddBlockForm />
            </Box>

            <Box id="apa" sx={{ width: "100%", height: "100%" }}>
              <form
                onSubmit={handleSubmit}
                style={{ height: "100%", margin: 0, padding: 0 }}
              >
                <Box
                  id="hej"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    overflowY: "scroll",
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      overflowX: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    <Box
                      sx={{
                        width: "calc(100%)",
                        padding: "4em 0px 200px 0px",
                      }}
                    >
                      {(() => {
                        if (!currentBlock) return <></>;
                        const EditComponent = blockTypes.find(
                          ({ isDataBlock }) => isDataBlock(currentBlock as any)
                        )?.EditComponent;
                        return EditComponent ? (
                          <Box sx={{ padding: "0em 1em 0em 1em" }}>
                            <EditComponent {...(currentBlock as any)} />
                          </Box>
                        ) : (
                          <></>
                        );
                      })()}
                    </Box>
                  </Box>
                  <Box
                    position="absolute"
                    bottom={0}
                    width="calc(100%)"
                    margin="0em 0em 0em 0em"
                    sx={{
                      background:
                        "linear-gradient(to bottom,rgba(193, 191, 191, 0.8),rgb(255, 255, 255, 0.8))",
                    }}
                  >
                    <Box sx={{ padding: "1em", borderTop: "1px solid #666" }}>
                      <Box sx={{}}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          disabled={!hasChanges}
                          fullWidth
                        >
                          Save
                        </Button>
                      </Box>
                      <Box sx={{}}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          size="large"
                          fullWidth
                          disabled={!hasChanges}
                          onClick={() => cancelChanges()}
                          sx={{ mt: 1, mb: 1 }}
                        >
                          Cancel changes
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </form>
            </Box>
          </SlideView>
        </Drawer>
        {/* Drag handle */}
        <Box
          sx={{
            width: "5px",
            cursor: "ew-resize",
            backgroundColor: "separator",
            zIndex: 1201,
            marginLeft: isOpen ? `${editorWidth}px` : "-5px",
            transition: isResizing ? "none" : "margin-left 0.3s ease-in-out",
          }}
          onMouseDown={handleMouseDown}
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Box>{children}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContentEditor;
