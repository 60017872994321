import { WidthFull } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";

type ResponsiveTextProps = {
  copy: string;
};

const ResponsiveText: React.FC<ResponsiveTextProps> = ({ copy }) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);
  const [fontSize, setFontSize] = useState(16); // Startstorlek

  const adjustFontSize = () => {
    if (!boxRef.current || !textRef.current) return;

    const { width: boxWidth, height: boxHeight } =
      boxRef.current?.getBoundingClientRect() || { width: 0, height: 0 };

    let minSize = 10;
    let maxSize = 200; // Rimligt maxvärde
    let bestSize = minSize;

    // Binärsökning för att hitta största passande fontSize
    while (minSize <= maxSize) {
      const midSize = Math.floor((minSize + maxSize) / 2);
      textRef.current.style.fontSize = `${midSize}px`;

      const { width: textWidth, height: textHeight } =
        textRef.current.getBoundingClientRect();

      if (textWidth <= boxWidth && textHeight <= boxHeight) {
        bestSize = midSize; // Spara bästa hittills
        minSize = midSize + 1; // Testa större storlek
      } else {
        maxSize = midSize - 1; // Testa mindre storlek
      }
    }

    setFontSize(bestSize); // Sätt största fungerande fontSize
  };

  useEffect(() => {
    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);
    return () => window.removeEventListener("resize", adjustFontSize);
  }, []);

  useEffect(() => {
    adjustFontSize();
  }, [copy]);

  useEffect(() => {
    const element = boxRef.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      adjustFontSize();
    });

    resizeObserver.observe(element);

    return () => resizeObserver.disconnect();
  }, [boxRef]);

  return (
    <Box
      ref={boxRef}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden", // Viktigt för att förhindra utstickande text
        textAlign: "center",
      }}
    >
      <Typography
        ref={textRef}
        sx={{
          fontSize: `${fontSize}px`,
          whiteSpace: "nowrap",
          fontWeight: "bolder",
        }}
      >
        {copy}
      </Typography>
    </Box>
  );
};

export default ResponsiveText;
