export const payNowYear = () => {
  const today = new Date();
  const currentYear = today.getFullYear();

  const october31 = new Date(currentYear, 8, 30);
  if (today > october31) {
    return currentYear + 1;
  }

  return currentYear;
};
