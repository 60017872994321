export const sha256 = async (message: string) => {
  // Konvertera strängen till en buffer
  const encoder = new TextEncoder();
  const data = encoder.encode(message);

  // Beräkna hash med SubtleCrypto
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);

  // Konvertera buffer till hex-sträng
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};

export const hashEmail = async (email: unknown) => {
  if (typeof email !== "string") {
    return null;
  }
  if (!email.includes("@")) {
    return null;
  }
  if (!email.includes(".")) {
    return null;
  }
  if (email.length < 5) {
    return null;
  }
  if (email.length > 320) {
    return null;
  }
  if (email.includes(" ")) {
    return null;
  }
  if (email.includes(",")) {
    return null;
  }
  if (email.includes(";")) {
    return null;
  }
  if (email.includes(":")) {
    return null;
  }
  if (email.includes("\\")) {
    return null;
  }
  if (email.includes("/")) {
    return null;
  }
  return await sha256(email.trim().toLowerCase());
};
