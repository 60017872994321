import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, Typography, CircularProgress } from "@mui/material";

interface DropzoneProps {
  onFileAdded: (file: File | null) => void;
  accept?: string;
}

const Dropzone: React.FC<DropzoneProps> = ({ onFileAdded, accept }) => {
  const [isLoading, setIsLoading] = useState(false); // Laddningsstatus

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0] || null; // Endast den första filen accepteras
      setIsLoading(true); // Aktivera laddningsläge
      onFileAdded(file);
      setTimeout(() => setIsLoading(false), 2000); // Simulerar laddning (ta bort detta i riktig uppladdning)
    },
    [onFileAdded]
  );

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0] || null; // Endast första filen
    setIsLoading(true); // Aktivera laddningsläge
    onFileAdded(file);
    setTimeout(() => setIsLoading(false), 2000); // Simulerar laddning (ta bort detta i riktig uppladdning)
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "text/csv": [".csv"] },
    onDrop,
    multiple: false, // Tillåter endast en fil
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: "2px dashed #ccc",
        borderRadius: "8px",
        padding: "16px",
        textAlign: "center",
        cursor: isLoading ? "default" : "pointer",
        backgroundColor: isLoading ? "#e0e0e0" : "#f9f9f9",
        "&:hover": isLoading ? undefined : { backgroundColor: "#f0f0f0" },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "150px", // Fixerad höjd för att hålla samma storlek
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <input {...getInputProps()} style={{ display: "none" }} />
          <Typography variant="h6" color="textSecondary">
            Dra och släpp en fil här eller
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component="label"
            sx={{ marginTop: "8px" }}
          >
            Ladda upp fil
            <input
              type="file"
              hidden
              onChange={handleFileInputChange}
              accept={accept ? accept : "*/*"}
            />
          </Button>
        </>
      )}
    </Box>
  );
};

export default Dropzone;
