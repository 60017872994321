import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";
import { removeTrailingSlash } from "./utils/urls";
import { BlockData } from "./blocks/blockType";

interface ContentContextType {
  blocks: BlockData[];
  isLoading: boolean;
  error: string | null;
  is404: boolean;
  endpoints: string[];
  setEndpoints: React.Dispatch<React.SetStateAction<string[]>>;
  setIs404: React.Dispatch<React.SetStateAction<boolean>>;
  updateBlock: (block: BlockData) => void;
  moveBlock: (id: string, afterId: string | null) => void;
  setBlocks: React.Dispatch<React.SetStateAction<BlockData[]>>;
  deleteBlock: (blockId: string) => void;
  addBlock: (block: BlockData) => void;
  addNewEndpoint: (endpoint: string) => void;
  changeEndpoint: (oldEndpoint: string, newEndpoint: string) => void;
  deletePage: (endpoint: string) => void;
  title: string;
}

const ContentContext = createContext<ContentContextType | undefined>(undefined);

// Provider
export const ContentProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [blocks, setBlocks] = useState<BlockData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [is404, setIs404] = useState<boolean>(false);
  const [endpoints, setEndpoints] = useState<string[]>([]);
  const [title, setTitle] = useState<string>("");

  const location = useLocation(); // Hämta aktuell route

  const addNewEndpoint = async (endpoint: string) => {
    const response = await fetch(`/command/ADD_PAGE`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        endpoint,
      }),
    });
    fetchEndpoints();
  };

  const deletePage = async (endpoint: string) => {
    console.log("Deleting page", endpoint);
    const response = await fetch(`/command/DELETE_PAGE`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        endpoint,
      }),
    });
    fetchEndpoints();
  };

  const changeEndpoint = async (oldEndpoint: string, newEndpoint: string) => {
    const response = await fetch(`/command/CHANGE_PAGE_ENDPOINT`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        oldEndpoint,
        newEndpoint,
      }),
    });
    fetchEndpoints();
  };

  const addBlock = async (block: BlockData) => {
    const { blockId, ...blockWithoutId } = block;
    const response = await fetch(`/command/ADD_BLOCK`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...blockWithoutId,
        afterBlockId:
          blocks.length > 0 ? blocks[blocks.length - 1].blockId : null,
      }),
    });

    if (!response.ok) {
      console.log("Failed to add block");
    }

    fetchData();
  };

  const deleteBlock = async (blockId: string) => {
    setBlocks(blocks.filter((b: BlockData) => b.blockId !== blockId));

    const response = await fetch(`/command/DELETE_BLOCK`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        blockId,
        endpoint: blocks.find((b: BlockData) => b.blockId === blockId)
          ?.endpoint,
      }),
    });

    if (!response.ok) {
      console.log("Failed to delete block");
    }
  };

  const updateBlock = async (block: BlockData) => {
    setBlocks(
      blocks.map((b: BlockData) => (b.blockId === block.blockId ? block : b))
    );
    const response = await fetch(`/command/UPDATE_BLOCK`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(block),
    });

    if (!response.ok) {
      console.log("Failed to update block", block);
    }
  };

  const moveBlock = (id: string, afterId: string | null) => {
    const block = blocks.find((b: BlockData) => b.blockId === id);
    if (!block) {
      return;
    }

    (async () => {
      const response = await fetch(`/command/MOVE_BLOCK`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          endpoint: block.endpoint,
          blockId: block.blockId,
          afterBlockId: afterId,
        }),
      });
      if (!response.ok) {
        console.log("Failed to update block");
      }
    })();

    const newBlocks = afterId === null ? [block] : [];
    blocks
      .filter((b: BlockData) => b.blockId !== id)
      .forEach((b: BlockData) => {
        newBlocks.push(b);
        if (b.blockId === afterId) {
          newBlocks.push(block);
        }
      });
    setBlocks(newBlocks);
  };

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // Anpassa endpoint baserat på URL
      const endpoint = removeTrailingSlash(location.pathname);

      const response = await fetch(`/query/page${endpoint}/page.json`);
      if (!response.ok) {
        setBlocks([]);
        setIs404(true);
        throw new Error("Failed to fetch data");
      }
      const result = await response.json();
      const blocks = (result.blocks || []).map(
        (block: object) =>
          ({
            ...block,
            endpoint,
          } as BlockData)
      );
      console.log(blocks);
      setIs404(false);
      setBlocks(blocks);
      setTitle(result.title);
    } catch (err: any) {
      setBlocks([]);
      setIs404(true);
      setError(err.message);
      setTitle("Error loading page");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEndpoints = async () => {
    const response = await fetch("/query/pages.json");
    if (!response.ok) {
      throw new Error("Failed to fetch endpoints");
    }
    const result = await response.json();
    setEndpoints(result.endpoints.toSorted());
  };

  useEffect(() => {
    fetchEndpoints();
  }, []);

  // Hämta data baserat på aktuell URL
  useEffect(() => {
    fetchData();
  }, [location.pathname]); // Kör när URL ändras

  return (
    <ContentContext.Provider
      value={{
        blocks,
        isLoading,
        error,
        updateBlock,
        moveBlock,
        setBlocks,
        deleteBlock,
        is404,
        setIs404,
        addBlock,
        endpoints,
        setEndpoints,
        addNewEndpoint,
        changeEndpoint,
        deletePage,
        title,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

export const useContent = (): ContentContextType => {
  const context = useContext(ContentContext);
  if (!context) {
    throw new Error("useContent must be used within a ContentProvider");
  }
  return context;
};
