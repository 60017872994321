import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  TextField,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useContent } from "../ContentContext";
import { Link, useLocation } from "react-router-dom";
import { useEditorContext } from "../EditorContext";
import { useNavigate } from "react-router-dom";
import { removeTrailingSlash } from "../utils/urls";
import EditTitle from "./EditTitle";

const Endpoints: React.FC = () => {
  const {
    endpoints,
    setEndpoints,
    addNewEndpoint,
    changeEndpoint,
    deletePage,
  } = useContent(); // Använd context för endpoints och setEndpoints
  const { setSlideAnimation, setEditorSlideIndex } = useEditorContext(); // Använd context för slide-animation och slide-index
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [tempUrl, setTempUrl] = useState<string>("");
  const [newLink, setNewLink] = useState<string>("");
  const [validNewLink, setValidNewLink] = useState<boolean>(false);
  const [validTempUrl, setValidTempUrl] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setValidNewLink(
      newLink.trim().length > 0 &&
        newLink.startsWith("/") &&
        !(newLink !== "/" && newLink.endsWith("/")) &&
        !newLink.endsWith(".") &&
        !newLink.endsWith("-") &&
        !newLink.includes("//") &&
        !newLink.includes("..") &&
        /^[a-z0-9_\-/]+$/i.test(newLink) &&
        !endpoints.includes(newLink)
    );
  }, [newLink, endpoints]);

  useEffect(() => {
    setValidTempUrl(
      tempUrl.trim().length > 0 &&
        tempUrl.startsWith("/") &&
        !(tempUrl !== "/" && newLink.endsWith("/")) &&
        !tempUrl.endsWith(".") &&
        !tempUrl.endsWith("-") &&
        !tempUrl.includes("//") &&
        !tempUrl.includes("..") &&
        /^[a-z0-9_\-/]+$/i.test(tempUrl) &&
        ((editingIndex !== null && tempUrl === endpoints[editingIndex]) ||
          !endpoints.includes(tempUrl))
    );
  }, [tempUrl, endpoints, editingIndex]);

  const handleDelete = async (index: number) => {
    const result = await fetch(
      `/query/page${endpoints[index].replace(/\/+$/, "")}/page.json`
    );
    const data = await result.json();
    console.log(data);
    if (data.blocks.length > 0) {
      window.alert(
        "This page is not empty and cannot be deleted. Please remove all blocks before deleting the page."
      );
    } else {
      if (window.confirm("Are you sure you want to delete this page?")) {
        deletePage(endpoints[index]);
      }
    }
  };

  const handleEdit = (index: number) => {
    setEditingIndex(index);
    setTempUrl(endpoints[index]);
  };

  const handleSave = async () => {
    if (editingIndex !== null) {
      const oldEndpoint = endpoints[editingIndex];
      const newEndpoint = tempUrl;

      if (oldEndpoint !== newEndpoint) {
        await changeEndpoint(oldEndpoint, newEndpoint);
        if (removeTrailingSlash(location.pathname) === oldEndpoint) {
          navigate(newEndpoint);
        }
      }

      setEditingIndex(null);
      setTempUrl("");
    }
  };

  const handleCancel = () => {
    setEditingIndex(null);
    setTempUrl("");
  };

  const handleAddLink = () => {
    if (validNewLink) {
      const endpoint = newLink.trim();
      addNewEndpoint(endpoint);
      setNewLink("");
    }
  };

  return (
    <>
      <EditTitle>Pages</EditTitle>

      <List sx={{ width: "100%" }}>
        {endpoints.map((url, index) => (
          <ListItem
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {editingIndex === index ? (
              // Redigeringsläge
              <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                <TextField
                  value={tempUrl}
                  onChange={(e) => setTempUrl(e.target.value)}
                  fullWidth
                  size="small"
                  sx={{ mr: 2 }}
                />
                <IconButton
                  onClick={handleSave}
                  aria-label="save"
                  disabled={!validTempUrl}
                  color="primary"
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  onClick={handleCancel}
                  aria-label="cancel"
                  color="secondary"
                >
                  <CancelIcon />
                </IconButton>
              </Box>
            ) : (
              // Visningsläge
              <>
                <Box sx={{ flexGrow: 1 }}>
                  <Link
                    to={url}
                    onClick={() => {
                      setSlideAnimation(true);
                      setEditorSlideIndex(1);
                    }}
                  >
                    {url}
                  </Link>
                </Box>
                <IconButton onClick={() => handleEdit(index)} aria-label="edit">
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleDelete(index)}
                  aria-label="delete"
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </ListItem>
        ))}
      </List>

      {/* Formulär för att lägga till ny länk */}
      <EditTitle subtitle={true}>Add page</EditTitle>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <TextField
          value={newLink}
          onChange={(e) => setNewLink(e.target.value)}
          label="New page"
          variant="outlined"
          fullWidth
          size="small"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddLink}
          disabled={!validNewLink}
        >
          Add
        </Button>
      </Box>
    </>
  );
};

export default Endpoints;
