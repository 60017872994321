import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  email: string | null; // Användarens e-post
  loading: boolean;
}

const initialState: AuthState = {
  email: null,
  loading: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload; // Sätt användarens e-post
    },
    clearAuth: (state) => {
      state.email = null; // Rensa e-post, användaren loggas ut
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

// Exportera actions
export const { setEmail, clearAuth, setLoading } = authSlice.actions;

// Exportera reducer
export default authSlice.reducer;

// Selektor för isAuthenticated
export const selectIsAuthenticated = (state: { auth: AuthState }) =>
  state.auth.email !== null;
