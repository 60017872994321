import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { setEmail, clearAuth, setLoading } from "../store/authSlice";

const JwtHandler: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const parseJwt = (token: string) => {
      try {
        const base64Url = token.split(".")[1]; // Payload-delen av JWT
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Base64-url till standard Base64
        const jsonPayload = atob(base64); // Decode Base64
        return JSON.parse(jsonPayload); // Parsar JSON-payload
      } catch (error) {
        console.error("Fel vid parsning av JWT:", error);
        return null;
      }
    };

    const handleJwt = () => {
      const token = Cookies.get("jwt");
      if (!token) {
        dispatch(clearAuth());
        return;
      }

      const parsedToken = parseJwt(token);

      if (
        parsedToken &&
        typeof parsedToken === "object" &&
        "email" in parsedToken
      ) {
        dispatch(setEmail(parsedToken.email as string));
      } else {
        console.error("JWT saknar nödvändig information");
        Cookies.remove("jwt");
        dispatch(clearAuth());
      }
    };

    handleJwt();
    dispatch(setLoading(false)); // Indikerar att JwtHandler är klar
  }, [dispatch]);

  return null;
};

export default JwtHandler;
