import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

type FileUploadButtonProps = {
  onUpload: (sha256: string) => void;
};

const FileUploadButton: React.FC<FileUploadButtonProps> = ({ onUpload }) => {
  const [uploading, setUploading] = useState(false);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setUploading(true);
    try {
      const response = await uploadFile(file);
      onUpload(response.sha256);
      console.log("SHA-256:", response.sha256);
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setUploading(false);
    }
  };

  const uploadFile = async (file: File): Promise<{ sha256: string }> => {
    const url = "https://2d.uix.se/upload";

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      body: file,
    });

    if (!response.ok) {
      throw new Error("Upload failed");
    }

    return response.json();
  };

  return (
    <label>
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Button
        component="span"
        variant="contained"
        color="primary"
        startIcon={
          uploading ? <CircularProgress size={20} /> : <CloudUploadIcon />
        }
        disabled={uploading}
        sx={{ whiteSpace: "nowrap" }}
      >
        {uploading ? "Uploading..." : "Upload"}
      </Button>
    </label>
  );
};

export default FileUploadButton;
