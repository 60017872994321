import React from "react";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { closeDialog } from "../store/dialogSlice";

const PrivacyPolicyDialog: React.FC = () => {
  const dispatch = useDispatch();
  const activeDialog = useSelector(
    (state: RootState) => state.dialog.activeDialog
  );

  const handleClose = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog open={activeDialog === "privacypolicy"} onClose={handleClose}>
      <DialogTitle>Integritetspolicy</DialogTitle>
      <DialogContent dividers>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              1. Ansvar för personuppgifter
            </Typography>
            <Typography paragraph>
              Förbundet organisk-biologisk odling ansvarar för att hantera dina
              personuppgifter på ett säkert och transparent sätt enligt
              dataskyddsförordningen (GDPR). Vi samlar endast in de uppgifter
              som är nödvändiga för att administrera ditt medlemskap.
            </Typography>
            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" gutterBottom>
              2. Vilka uppgifter vi samlar in
            </Typography>
            <Typography paragraph>
              Vi samlar in följande uppgifter i vårt digitala medlems- och
              prenumerantregister:
            </Typography>
            <ul>
              <li>Namn</li>
              <li>Adress</li>
              <li>E-postadress</li>
              <li>Betalningshistorik (till exempel medlemsavgifter)</li>
            </ul>
            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" gutterBottom>
              3. Syfte med insamlingen
            </Typography>
            <Typography paragraph>
              Dina personuppgifter används endast för följande ändamål:
            </Typography>
            <ul>
              <li>Att administrera ditt medlemskap.</li>
              <li>
                Föreningsutskick, såsom medlemstidningen Odlaren och kallelser
                till föreningsmöten.
              </li>
              <li>Att hantera betalningar av medlemsavgifter.</li>
            </ul>
            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" gutterBottom>
              4. Lagring och skydd av uppgifter
            </Typography>
            <Typography paragraph>
              Uppgifterna lagras i ett säkert digitalt medlemsregister som
              endast är tillgängligt för behöriga personer, exempelvis
              föreningens styrelse eller medlemsansvariga. Vi använder
              kryptering och andra säkerhetsåtgärder för att skydda dina
              uppgifter.
            </Typography>
            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" gutterBottom>
              5. Dina rättigheter
            </Typography>
            <Typography paragraph>Enligt GDPR har du rätt att:</Typography>
            <ul>
              <li>Begära ett utdrag av de uppgifter vi har om dig</li>
              <li>Rätta felaktiga eller ofullständiga uppgifter</li>
              <li>
                Begära att dina uppgifter raderas, om det inte strider mot
                lagkrav
              </li>
            </ul>
            <Typography paragraph>
              För att utöva dina rättigheter kan du kontakta oss via vår hemsida
              eller e-post.
            </Typography>
            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" gutterBottom>
              6. Samtycke
            </Typography>
            <Typography paragraph>
              Genom att bli medlem i föreningen samtycker du till att vi
              behandlar dina personuppgifter enligt denna policy. Om du har
              några frågor eller funderingar är du välkommen att kontakta oss.
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Stäng
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrivacyPolicyDialog;
