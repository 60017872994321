import React from "react";
import { Container, Box, Typography, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <Box component="footer" sx={{ bgcolor: "#333", color: "white", py: 3 }}>
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="body1" sx={{ color: "#aaa" }}>
              Kontakta oss:
            </Typography>
            <Typography variant="body2">
              Email:{" "}
              <Link to="mailto:kansli@fobo.se" style={{ color: "#fff" }}>
                kansli@fobo.se
              </Link>
            </Typography>
            <Typography variant="body2">Telefon: 070-423 79 69</Typography>
            <br />
            <Typography variant="body2">
              Förbundet organisk-biologisk odling
              <br />
              Ycke Stora Askefällan
              <br />
              590 53 Ulrika
            </Typography>
          </Box>
          <Box>
            <IconButton
              aria-label="facebook"
              href="https://www.facebook.com/atFobo/"
              target="_blank"
              rel="noopener"
              sx={{ color: "white" }}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              aria-label="instagram"
              href="https://www.instagram.com/fobo.se/"
              target="_blank"
              rel="noopener"
              sx={{ color: "white" }}
            >
              <InstagramIcon />
            </IconButton>
          </Box>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="body1" mt={2} sx={{ color: "#aaa" }}>
              Vi är medlemmar i:
              <br />
              <Link to="https://for.se/" target="_blank" rel="noopener">
                <img src="/for.png" alt="För" style={{ width: "200px" }} />
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
