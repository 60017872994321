import { Box } from "@mui/material";
import ZoomableImage from "./ZoomableImage";
import { useEffect, useState } from "react";

type ImgProps = {
  imageHash: string;
  focalX: number;
  focalY: number;
  zoom: number;
  aspectRatio: number;
  beingEdited: boolean;
  sx?: React.CSSProperties;
  borderRadius?: number;
};

const Img: React.FC<ImgProps> = ({
  imageHash,
  focalX,
  focalY,
  zoom,
  aspectRatio,
  beingEdited,
  borderRadius,
  sx = {},
}) => {
  const [loadTimeout, setLoadTimeout] = useState<NodeJS.Timeout | null>(null);
  const [url, setUrl] = useState<string>(
    `https://2d.uix.se/image/${imageHash}.webp?width=610&height=${Math.floor(
      610 / aspectRatio
    )}&focalX=${focalX}&focalY=${focalY}&zoom=${zoom}`
  );

  useEffect(() => {
    const updateUrl = () =>
      setUrl(
        `https://2d.uix.se/image/${imageHash}.webp?width=610&height=${Math.floor(
          610 / aspectRatio
        )}&focalX=${focalX}&focalY=${focalY}&zoom=${zoom}`
      );

    if (!beingEdited) {
      updateUrl();
    } else {
      if (loadTimeout) {
        clearTimeout(loadTimeout);
      }
      setLoadTimeout(setTimeout(updateUrl, 500));
    }
  }, [imageHash, focalX, focalY, zoom, aspectRatio, beingEdited]);

  return (
    <Box
      sx={{
        width: "100%",
        aspectRatio: aspectRatio,
        margin: 0,
        padding: 0,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        sx={{
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        {beingEdited ? (
          <>
            <ZoomableImage
              src={`https://2d.uix.se/image/${imageHash}.webp`}
              aspectRatio={aspectRatio}
              zoom={zoom}
              focalX={focalX}
              focalY={focalY}
              borderRadius={borderRadius}
            />
          </>
        ) : (
          <Box
            component="img"
            src={url}
            alt=""
            sx={{
              ...(borderRadius ? { borderRadius: borderRadius } : {}),
              margin: 0,
              padding: 0,
              width: "100%",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Img;
