import { z } from "zod";
import { blockDataSchema } from "./blockDataSchema";

export type BlockData = z.infer<typeof blockDataSchema> & {
  endpoint?: string;
  blockId?: string;
};

export const isBlockData = (
  block: unknown
): block is BlockData & { blockId: string; endpoint: string } =>
  blockDataSchema.safeParse(block).success;

export type WrapperComponentProps = {
  children: React.ReactNode;
};

export type Block<T extends BlockData> = {
  id: T["blockType"]; // Must be same as blockType in blockDataSchema
  name: string;
  isDataBlock: (block: T) => block is T;
  Component: React.FC<T>;
  ListComponent: React.FC<T>;
  EditComponent: React.FC<T>;
  WrapperComponent?: React.FC<WrapperComponentProps>;
  initialBlock: () => Omit<T, "blockId" | "endpoint">;
};
