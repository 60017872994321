import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { TextField, Button } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useEditorContext } from "../EditorContext";
import { Block } from "./blockType";
import { titleBlockDataSchema } from "./blockDataSchema";
import { z } from "zod";
import { loremIpsum } from "../utils/loremipsum";
import EditTitle from "../components/EditTitle";
import { Label } from "@mui/icons-material";

const id = "title" as const;
const name = "Title/Subtitle" as const;

type TitleDataBlock = z.infer<typeof titleBlockDataSchema>;

const isDataBlock = (block: unknown): block is TitleDataBlock =>
  titleBlockDataSchema.safeParse(block).success;

const Component: React.FC<TitleDataBlock> = (block) => {
  return (
    <Typography
      component="h1"
      variant="h3"
      sx={{
        color: block.title.trim() ? "black" : "red",
        fontWeight: "bolder",
        fontSize: block.subtitle ? "1.6em" : "3em",
      }}
    >
      {block.title.trim() || "- No title -"}
    </Typography>
  );
};

const ListComponent: React.FC<TitleDataBlock> = (block) => {
  return (
    <>
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <strong>{block.subtitle ? "Subtitle" : "Title"}</strong> {block.title}
      </Box>
    </>
  );
};

const EditComponent: React.FC<TitleDataBlock> = (block) => {
  const { setWorkInProgress } = useEditorContext();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Sätt fokus på elementet när komponenten renderas
    if (inputRef.current) {
      // Pajjar pagineringen i renderingen i SlideView.
      // Kanske testa med att senarelägga detta?
      //inputRef.current.focus();
      //inputRef.current.select();
    }
  }, []); // Tom array gör att detta bara körs vid den första renderingen

  return (
    <>
      <EditTitle>Title</EditTitle>

      <TextField
        label="Titel"
        variant="outlined"
        fullWidth
        value={block.title}
        onChange={(e) => setWorkInProgress({ ...block, title: e.target.value })}
        required
        sx={{ marginBottom: 2, backgroundColor: "white" }}
        inputRef={inputRef}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={block.subtitle ?? false}
            onChange={(event) =>
              setWorkInProgress({ ...block, subtitle: event.target.checked })
            }
          />
        }
        label="Subtitle"
      />
    </>
  );
};

const initialBlock: () => Omit<
  TitleDataBlock,
  "blockId" | "endpoint"
> = () => ({
  blockType: "title" as const,
  title: loremIpsum(Math.floor(Math.random() * 5) + 3),
  subtitle: false,
});

const block: Block<TitleDataBlock> = {
  id,
  name,
  isDataBlock: isDataBlock,
  Component,
  ListComponent,
  EditComponent,
  initialBlock,
};

export default block;
