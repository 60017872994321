import React from "react";

type YouTubeEmbedProps = {
  url: string;
};

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ url }) => {
  const extractVideoId = (url: string): string | null => {
    const match = url.match(
      /(?:youtu\.be\/|youtube\.com\/(?:.*v=|.*\/|embed\/))([^?&]+)/
    );
    return match ? match[1] : null;
  };

  const videoId = extractVideoId(url);

  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "56.25%",
        height: 0,
        overflow: "hidden",
        maxWidth: "100%",
      }}
    >
      {videoId ? (
        <iframe
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
          sandbox="allow-scripts allow-same-origin"
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      ) : (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#000",
            color: "#fff",
          }}
        >
          Ogiltig YouTube-URL
        </div>
      )}
    </div>
  );
};

export default YouTubeEmbed;
