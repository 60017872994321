import { Box, Typography } from "@mui/material";
import RichTextEditor from "../components/RichTextEditor";
import { useEditorContext } from "../EditorContext";
import HtmlContent from "../components/HtmlContent";
import { ingressBlockDataSchema } from "./blockDataSchema";
import { z } from "zod";
import { Block } from "./blockType";
import { removeTrailingSlash } from "../utils/urls";
import { loremIpsum } from "../utils/loremipsum";

const id = "ingress" as const;
const name = "Ingress" as const;

type IngressDataBlock = z.infer<typeof ingressBlockDataSchema>;

const isDataBlock = (block: unknown): block is IngressDataBlock =>
  ingressBlockDataSchema.safeParse(block).success;

export const Component: React.FC<IngressDataBlock> = ({ ingress }) => {
  return (
    <Box>
      <HtmlContent
        html={ingress}
        sx={{ fontWeight: 600, fontSize: "1.2em", padding: 0, margin: 0 }}
      />
    </Box>
  );
};

const ListComponent: React.FC<IngressDataBlock> = (block) => {
  return (
    <>
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <strong>Ingress</strong> {block.ingress.replace(/<\/?[^>]+(>|$)/g, "")}
      </Box>
    </>
  );
};

const EditComponent: React.FC<IngressDataBlock> = (block) => {
  const { setWorkInProgress } = useEditorContext();

  return (
    <>
      <Typography variant="h5" component="h1" gutterBottom>
        Skriv in en ingress
      </Typography>

      <RichTextEditor
        value={block.ingress}
        sx={{ height: "200px", backgroundColor: "white" }}
        onChange={(value) => setWorkInProgress({ ...block, ingress: value })}
        height={200}
      />
    </>
  );
};

const initialBlock: () => Omit<
  IngressDataBlock,
  "blockId" | "endpoint"
> = () => ({
  blockType: "ingress" as const,
  ingress: `<p>${loremIpsum(20)}</p>`,
});

const block: Block<IngressDataBlock> = {
  id,
  name,
  isDataBlock,
  Component,
  ListComponent,
  EditComponent,
  initialBlock,
};

export default block;
