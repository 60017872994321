import React from "react";
import { Box, Typography } from "@mui/material";

import { QRCodeSVG } from "qrcode.react";
import { Link } from "react-router-dom";
import { paymentLinks } from "../utils/swish";

interface SwishQRCodeProps {
  amount: number;
  message: string;
}

const SwishQRCode: React.FC<SwishQRCodeProps> = ({ amount, message }) => {
  const { url, qrCode } = paymentLinks(amount, message);

  return (
    <Box sx={{ mt: 3 }}>
      <Link to={url} style={{ textDecoration: "none" }}>
        <QRCodeSVG value={qrCode} size={200} />
      </Link>
      <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
        Skanna QR-koden med din Swish-app.
      </Typography>
    </Box>
  );
};

export default SwishQRCode;
