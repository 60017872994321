import React, { useState } from "react";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Paper, List, Box, Button, Typography } from "@mui/material";
import SortableItem from "./SortableItem"; // Vi definierar SortableItem separat.
import { useContent } from "../ContentContext";
import { useEditorContext } from "../EditorContext";
import DeleteIcon from "@mui/icons-material/Delete";
import blockTypes from "../blocks";
import EditorNavLink from "./EditorNavLink";

const SortableList: React.FC = () => {
  const { moveBlock, blocks, deleteBlock } = useContent();
  const {
    setEditorFocus,
    setSlideAnimation,
    setEditorSlideIndex,
    setWorkInProgress,
    editorWidth,
    setDeleteBlockPrompt,
    deleteBlockPrompt,
    setHighlightedContent,
    highlightedContent,
    blockListHighlight,
  } = useEditorContext();
  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = ({ active, over }: { active: any; over: any }) => {
    if (!over || active.id === over.id) return;

    const oldIndex = blocks.findIndex((block) => block.blockId === active.id);
    const newIndex = blocks.findIndex((block) => block.blockId === over.id);

    moveBlock(
      active.id,
      newIndex === 0
        ? null
        : blocks.filter((block) => block.blockId !== active.id)[newIndex - 1]
            .blockId
    );

    setTimeout(() => {
      const elem = document.getElementById(`block-${active.id}`);
      if (elem) {
        elem.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }, 100);
  };

  return (
    <Box>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={blocks.map((block) => block.blockId)}
          strategy={verticalListSortingStrategy}
        >
          <List
            component={Paper}
            sx={{
              margin: "0 auto",
              padding: 0,
              background: "transparent",
              border: "none",
            }}
          >
            {blocks.map((block) => (
              <SortableItem
                key={block.blockId}
                id={block.blockId}
                highlighted={blockListHighlight === block.blockId}
                scrollToContent={() => {
                  const elem = document.getElementById(
                    `block-${block.blockId}`
                  );
                  if (elem) {
                    elem.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "center",
                    });
                  }
                }}
                highlightContent={() => {
                  console.log("Mouse enter", block.blockId);
                  const elem = document.getElementById(
                    `block-${block.blockId}`
                  );
                  if (elem) {
                    setHighlightedContent(elem);
                  }
                }}
                unhighlightContent={() => {
                  console.log("Mouse leave", block.blockId);
                  setHighlightedContent(null);
                }}
              >
                <Box
                  sx={{
                    cursor: "move",
                    width: "100%",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <Box
                    sx={{
                      padding: 0,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexBasis: 0,
                      gap: 0,
                      margin: 0,
                    }}
                  >
                    <Box
                      sx={{
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Button
                        onClick={() => {
                          setDeleteBlockPrompt(block.blockId);
                        }}
                        sx={{
                          padding: 0,
                          margin: "0px 0px 0px -20px",
                        }}
                        onPointerDown={(event) => event.stopPropagation()}
                      >
                        <DeleteIcon
                          color={
                            deleteBlockPrompt === block.blockId
                              ? "error"
                              : "disabled"
                          }
                        />{" "}
                      </Button>
                    </Box>

                    <Box
                      sx={{
                        flexGrow: 1,
                        flexShrink: 1,
                        flexBasis: 0,
                        overflow: "hidden",
                      }}
                    >
                      {(() => {
                        const ListComponent = blockTypes.find(
                          ({ isDataBlock }) => isDataBlock(block as any)
                        )?.ListComponent;
                        return ListComponent ? (
                          <ListComponent {...(block as any)} />
                        ) : (
                          <></>
                        );
                      })()}
                    </Box>
                    <Box
                      sx={{
                        flex: "0 0 50px",
                        textAlign: "right",
                      }}
                    >
                      {deleteBlockPrompt === block.blockId ? (
                        <Box
                          sx={{
                            padding: 0,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexBasis: 0,
                            gap: 1,
                          }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => {
                              deleteBlock(block.blockId);
                            }}
                            onPointerDown={(event) => event.stopPropagation()}
                          >
                            Delete!
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => {
                              setDeleteBlockPrompt(null);
                            }}
                            onPointerDown={(event) => event.stopPropagation()}
                          >
                            Cancel
                          </Button>
                        </Box>
                      ) : (
                        <EditorNavLink
                          copy="Edit"
                          direction="right"
                          onClick={() => {
                            setSlideAnimation(true);
                            setEditorSlideIndex(2);
                            setEditorFocus(block.blockId);
                            setWorkInProgress(null);
                          }}
                          onPointerDown={(event) => event.stopPropagation()}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </SortableItem>
            ))}
          </List>
        </SortableContext>
      </DndContext>
    </Box>
  );
};

export default SortableList;
