import React, { useState, useEffect, useRef } from "react";
import { useEditorContext } from "../EditorContext";

export default function HighlightOverlay() {
  const { highlightedContent, editorWidth } = useEditorContext();

  const [rect, setRect] = useState<DOMRect | null>(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const overlayRef = useRef<HTMLDivElement | null>(null);

  // 1) Uppdatera windowSize när fönstret ändrar storlek
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // 2) Kör en requestAnimationFrame-loop som kontinuerligt uppdaterar positionen
  useEffect(() => {
    if (!highlightedContent) {
      setRect(null);
      return;
    }
    let rAFId: number;

    function updateRect() {
      if (!highlightedContent) return;
      setRect(highlightedContent.getBoundingClientRect());
      rAFId = requestAnimationFrame(updateRect);
    }

    rAFId = requestAnimationFrame(updateRect);

    return () => cancelAnimationFrame(rAFId);
  }, [highlightedContent]);

  // Om inget element är highlightat → ingen overlay
  if (!rect) return null;

  // --- Overlayens storlek ---
  // Overlayen täcker skärmen från x = editorWidth till x = windowSize.width.
  const overlayWidth = windowSize.width - editorWidth;
  const overlayHeight = windowSize.height;

  // --- Beräkna hålets position inom overlayens koordinatsystem ---
  // Notera att overlayen börjar vid x = editorWidth på skärmen,
  // medan getBoundingClientRect() är relativt skärmens (0,0) uppe till vänster.
  const padding = 8;
  const holeTop = rect.top - padding; // sammas som i globalt y-läge
  const holeLeftInOverlay = rect.left - editorWidth - padding;
  // (Där 0 i overlayen är x=editorWidth i globala koordinater)

  const holeWidth = rect.width + padding * 2;
  const holeHeight = rect.height + padding * 2;

  // Clip-path med inset() anger: inset(top, right, bottom, left)
  // - top: hur långt ner från överkanten "klippet" börjar
  // - right: hur långt in från högerkanten
  // - bottom: hur långt upp från nederkanten
  // - left: hur långt in från vänsterkanten
  //
  // Ex:
  //   clip-path: inset(10px, 20px, 30px, 40px)
  //   betyder en synlig ruta som är:
  //     top=10px ner
  //     right=20px från höger
  //     bottom=30px från botten
  //     left=40px från vänster
  //
  // Om du vill "skära ut" en rektangel runt (holeLeftInOverlay, holeTop) med holeWidth/holeHeight,
  // blir:
  //   top = holeTop
  //   left = holeLeftInOverlay
  //   bottom = overlayHeight - (holeTop + holeHeight)
  //   right = overlayWidth - (holeLeftInOverlay + holeWidth)

  const topInset = holeTop;
  const leftInset = holeLeftInOverlay;
  const bottomInset = overlayHeight - (holeTop + holeHeight);
  const rightInset = overlayWidth - (holeLeftInOverlay + holeWidth);

  // Om hålet är delvis utanför overlayen (t.ex. för långt åt vänster),
  // kan topInset/leftInset bli negativa. Det gör i praktiken att klippet
  // går utanför overlayen, vilket kan vara OK. Annars kan man maxa dem med 0.

  // 3) Bygg samman clipPath-strängen (in med rundning om du vill)
  const borderRadius = 10;
  // "round <radius>" ger rundade hörn på hålet
  const clipPath = `inset(
    ${topInset < 0 ? 0 : topInset}px
    ${rightInset < 0 ? 0 : rightInset}px
    ${bottomInset < 0 ? 0 : bottomInset}px
    ${leftInset < 0 ? 0 : leftInset}px
    round ${borderRadius}px
  )`;

  return (
    <div
      ref={overlayRef}
      style={{
        position: "fixed",
        top: 0,
        left: editorWidth, // börja overlayen efter editorn
        width: overlayWidth, // resten av skärmen
        height: overlayHeight, // full höjd
        backgroundColor: "rgba(190, 102, 102, 0.2)",
        clipPath,
        // pointerEvents: "none", // om du vill att klick ska gå igenom hålet
        zIndex: 9999,
      }}
    />
  );
}
