export function getOverlappingValues<T>(array1: T[], array2: T[]): T[] {
  const set1 = new Set(array1);
  const overlappingValues = new Set<T>();

  for (const item of array2) {
    if (set1.has(item)) {
      overlappingValues.add(item);
    }
  }

  return Array.from(overlappingValues);
}
