import { Box, Typography } from "@mui/material";
import RichTextEditor from "../components/RichTextEditor";
import { useEditorContext } from "../EditorContext";
import HtmlContent from "../components/HtmlContent";
import { bodyTextBlockDataSchema } from "./blockDataSchema";
import { z } from "zod";
import { Block } from "./blockType";
import { removeTrailingSlash } from "../utils/urls";
import { loremIpsum } from "../utils/loremipsum";
import EditTitle from "../components/EditTitle";

const id = "bodyText" as const;
const name = "Body Text" as const;

type BodyTextDataBlock = z.infer<typeof bodyTextBlockDataSchema>;

const isDataBlock = (block: unknown): block is BodyTextDataBlock =>
  bodyTextBlockDataSchema.safeParse(block).success;

export const Component: React.FC<BodyTextDataBlock> = ({ bodyText }) => {
  return (
    <Box>
      <HtmlContent html={bodyText} sx={{ lineHeight: 1.6 }} />
    </Box>
  );
};

const ListComponent: React.FC<BodyTextDataBlock> = (block) => {
  return (
    <>
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <strong>Body text</strong>{" "}
        {block.bodyText.replace(/<\/?[^>]+(>|$)/g, "")}
      </Box>
    </>
  );
};

const EditComponent: React.FC<BodyTextDataBlock> = (block) => {
  const { setWorkInProgress } = useEditorContext();

  return (
    <>
      <EditTitle>Body text</EditTitle>

      <RichTextEditor
        value={block.bodyText}
        sx={{ height: "350px", backgroundColor: "white" }}
        height={350}
        onChange={(value) => setWorkInProgress({ ...block, bodyText: value })}
      />
    </>
  );
};

const initialBlock: () => Omit<
  BodyTextDataBlock,
  "blockId" | "endpoint"
> = () => ({
  blockType: "bodyText" as const,
  bodyText: `<p>${loremIpsum(20)}</p><p>${loremIpsum(50)}</p><p>${loremIpsum(
    30
  )}</p>`,
});

const block: Block<BodyTextDataBlock> = {
  id,
  name,
  isDataBlock,
  Component,
  ListComponent,
  EditComponent,
  initialBlock,
};

export default block;
