import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { closeDialog } from "../store/dialogSlice";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

type MembersImportConfirmationDialogProps = {
  children: React.ReactNode;
};

const MembersImportConfirmationDialog: React.FC<
  MembersImportConfirmationDialogProps
> = ({ children }) => {
  const dispatch = useDispatch();
  const activeDialog = useSelector(
    (state: RootState) => state.dialog.activeDialog
  );

  const handleClose = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog open={activeDialog === "membersimport"} onClose={handleClose}>
      <DialogTitle>Import</DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Stäng
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MembersImportConfirmationDialog;
