import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { TextField, Button } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useEditorContext } from "../EditorContext";
import { Block } from "./blockType";
import { quoteBlockDataSchema } from "./blockDataSchema";
import { z } from "zod";
import { loremIpsum } from "../utils/loremipsum";
import EditTitle from "../components/EditTitle";
import { Label } from "@mui/icons-material";

const id = "quote" as const;
const name = "Quote" as const;

type QuoteDataBlock = z.infer<typeof quoteBlockDataSchema>;

const isDataBlock = (block: unknown): block is QuoteDataBlock =>
  quoteBlockDataSchema.safeParse(block).success;

const Component: React.FC<QuoteDataBlock> = (block) => {
  return (
    <Box
      sx={{
        padding: "1em 2em 1em 2em",
        margin: "0em 2em 0em 2em",
        background: "#eee",
        borderRadius: "8px",
      }}
    >
      <Typography
        component="blockquote"
        variant="h6"
        sx={{
          color: block.quote.trim() ? "gray" : "red",
          fontWeight: "normal",
          fontStyle: "italic",
          padding: "0em 0em 0em 0em",
        }}
      >
        "{block.quote.trim() || "- No Quote -"}"
      </Typography>
      {block.source && (
        <Typography
          component="p"
          variant="body2"
          sx={{
            color: block.quote.trim() ? "gray" : "red",
            fontWeight: "normal",
            textAlign: "right",
            fontStyle: "italic",
            padding: "0em 0em 0em 0em",
          }}
        >
          &mdash; {block.source.trim()}
        </Typography>
      )}
    </Box>
  );
};

const ListComponent: React.FC<QuoteDataBlock> = (block) => {
  return (
    <>
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <strong>Quote</strong> {block.quote}
      </Box>
    </>
  );
};

const EditComponent: React.FC<QuoteDataBlock> = (block) => {
  const { setWorkInProgress } = useEditorContext();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Sätt fokus på elementet när komponenten renderas
    if (inputRef.current) {
      // Pajjar pagineringen i renderingen i SlideView.
      // Kanske testa med att senarelägga detta?
      //inputRef.current.focus();
      //inputRef.current.select();
    }
  }, []); // Tom array gör att detta bara körs vid den första renderingen

  return (
    <>
      <EditTitle>Quote</EditTitle>

      <TextField
        label="Quote"
        variant="outlined"
        fullWidth
        value={block.quote}
        onChange={(e) => setWorkInProgress({ ...block, quote: e.target.value })}
        required
        sx={{ marginBottom: 2, backgroundColor: "white" }}
        inputRef={inputRef}
      />
      <TextField
        label="Source"
        variant="outlined"
        fullWidth
        value={block.source}
        onChange={(e) =>
          setWorkInProgress({ ...block, source: e.target.value })
        }
        sx={{ marginBottom: 2, backgroundColor: "white" }}
      />
    </>
  );
};

const initialBlock: () => Omit<
  QuoteDataBlock,
  "blockId" | "endpoint"
> = () => ({
  blockType: "quote" as const,
  quote: loremIpsum(Math.floor(Math.random() * 5) + 3),
  source: "",
});

const block: Block<QuoteDataBlock> = {
  id,
  name,
  isDataBlock: isDataBlock,
  Component,
  ListComponent,
  EditComponent,
  initialBlock,
};

export default block;
