import { Box, Slider, Typography } from "@mui/material";
import { TextField, Button } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useEditorContext } from "../EditorContext";
import { Block } from "../blocks/blockType";
import {
  imageBlockDataSchema,
  titleBlockDataSchema,
} from "../blocks/blockDataSchema";
import { z } from "zod";
import { loremIpsum } from "../utils/loremipsum";
import ZoomableImage from "./ZoomableImage";
import FileUploadButton from "./FileUploadButton";

type ImgEditProps = {
  image: string;
  focalX: number;
  focalY: number;
  zoom: number;
  onFocalChange: (focalX: number, focalY: number) => void;
  onZoomChange: (zoom: number) => void;
  onNewImage: (sha256: string) => void;
  aspectRatio: number;
};

const ImgEdit: React.FC<ImgEditProps> = ({
  image,
  focalX,
  focalY,
  zoom,
  onFocalChange,
  onZoomChange,
  onNewImage,
  aspectRatio,
}) => {
  return (
    <>
      <ZoomableImage
        key={image}
        src={`https://2d.uix.se/image/${image}.webp`}
        aspectRatio={aspectRatio}
        zoom={zoom}
        focalX={focalX}
        focalY={focalY}
        onFocalChange={onFocalChange}
      />

      <Box display="flex" alignItems="center" gap={2}>
        <Typography variant="body2">Zoom:</Typography>
        <Slider
          value={zoom}
          min={0}
          max={700}
          step={1} // Ger flyttalsvärden
          onChange={(e) =>
            e.target &&
            "value" in e.target &&
            e.target.value &&
            onZoomChange(e.target.value as number)
          }
          aria-labelledby="float-slider"
        />
        <FileUploadButton onUpload={(sha256) => onNewImage(sha256)} />
      </Box>
    </>
  );
};

export default ImgEdit;
