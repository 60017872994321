import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Divider,
  Button,
  Box,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface EditorNavLinkProps {
  copy: string;
  direction: "right" | "left";
  onClick?: () => void;
  onPointerDown?: (event: React.PointerEvent) => void;
}

const EditorNavLink: React.FC<EditorNavLinkProps> = ({
  copy,
  direction,
  onPointerDown,
  onClick,
}) => {
  return (
    <Typography variant="body1">
      <Button
        onClick={onClick}
        onPointerDown={(event) => event.stopPropagation()}
      >
        {direction === "left" && (
          <ArrowBackIcon style={{ marginRight: "8px" }} />
        )}
        <Box sx={{ display: "inline-block", marginTop: "0.2em" }}>{copy}</Box>
        {direction === "right" && (
          <ArrowForwardIcon style={{ marginLeft: "8px" }} />
        )}
      </Button>
    </Typography>
  );
};

export default EditorNavLink;
