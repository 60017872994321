import { Typography } from "@mui/material";
import React from "react";

type PageHeaderProps = {
  children: React.ReactNode;
};

const PageHeader: React.FC<PageHeaderProps> = ({ children }) => {
  return (
    <Typography variant="h5" gutterBottom>
      {children}
    </Typography>
  );
};

export default PageHeader;
