import React, { useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { useLocation } from "react-router";
import { useContent } from "../ContentContext";
import { removeTrailingSlash } from "../utils/urls";
import blocks from "../blocks";
import { Block } from "../blocks/blockType";

const AddBlockForm: React.FC = () => {
  const location = useLocation(); // Hämta aktuell route

  const { addBlock } = useContent();

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value as string);
  };

  const handleSubmit = () => {
    const block = (() => {
      for (const block of blocks) {
        if (block.id === selectedOption) {
          return {
            ...block.initialBlock(),
            endpoint: removeTrailingSlash(location.pathname),
            blockId: "",
          };
        }
      }
    })();
    if (block) {
      addBlock(block);
    }
    setSelectedOption("");
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="dropdown-label">Choose block type</InputLabel>
        <Select
          labelId="dropdown-label"
          value={selectedOption}
          onChange={handleSelectChange}
          label="Choose block type"
          sx={{
            background: "white",
            width: "100%",
          }}
        >
          {blocks.map((block) => (
            <MenuItem key={block.id} value={block.id}>
              {block.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        variant="contained"
        onClick={handleSubmit}
        disabled={!selectedOption} // Inaktivera om inget är valt
      >
        Add
      </Button>
    </>
  );
};

export default AddBlockForm;
