import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import LandSelect from "../components/LandSelect"; // Importera komponenten för landval
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { openDialog } from "../store/dialogSlice";
import { useDispatch } from "react-redux";

import { config, getSubscriptionType } from "../utils/config";

const MembershipApplicationPage: React.FC = () => {
  const [formData, setFormData] = useState({
    membershipType: "",
    name: "",
    email: "",
    address: "",
    city: "",
    postalCode: "",
    country: "",
    isOrganization: false,
    localBranch: "",
    gdprConsent: false,
  });

  const [errors, setErrors] = useState({
    membershipType: false,
    name: false,
    email: false,
    address: false,
    city: false,
    postalCode: false,
    country: false,
    gdprConsent: false,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenPrivacyPolicyDialog = () => {
    dispatch(openDialog({ dialog: "privacypolicy" }));
  };

  const handleInputChange =
    (field: keyof typeof formData) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormData({
        ...formData,
        [field]: event.target.value,
      });
    };

  const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      gdprConsent: event.target.checked,
    });
    setErrors({
      ...errors,
      gdprConsent: !event.target.checked,
    });
  };

  const handleMembershipTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({
      ...formData,
      membershipType: event.target.value,
    });
    setErrors({
      ...errors,
      membershipType: event.target.value === "",
    });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      isOrganization: event.target.value === "organization",
    });
  };

  const validateField = (field: string, value: string | boolean): boolean => {
    if (field === "gdprConsent" && !value) {
      return true;
    }

    if (typeof value == "string") {
      if (
        field === "country" &&
        (!getSubscriptionType(formData.membershipType)?.foreign || value !== "")
      ) {
        return false;
      }

      if (field === "postalCode") {
        return (
          !getSubscriptionType(formData.membershipType)?.foreign &&
          !/^\d{3}\s?\d{2}$/.test(value.trim())
        );
      }

      if (field === "email") {
        return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim());
      }

      if (
        field === "city" &&
        getSubscriptionType(formData.membershipType)?.foreign
      ) {
        return false;
      }

      if (field === "localBranch") {
        // Allow eveything.
        return false;
      }

      return value.trim() === "";
    }
    return false;
  };

  const handleBlur = (field: keyof typeof formData) => {
    const value = formData[field];
    setErrors({
      ...errors,
      [field]:
        validateField(field, value) ||
        (field === "gdprConsent" && !formData.gdprConsent),
    });
  };

  const validateForm = () => {
    const newErrors = Object.keys(formData).reduce((acc, field) => {
      acc[field as keyof typeof errors] = validateField(
        field,
        formData[field as keyof typeof formData]
      );
      return acc;
    }, {} as typeof errors);

    console.log("validation errors", newErrors);

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = async () => {
    console.log("handleSubmit", formData);
    if (validateForm()) {
      console.log("Skickar formulär", formData);
      try {
        await fetch("https://loginmail.se/api/membership", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            address: formData.address,
            postalCode: formData.postalCode,
            city: formData.city,
            country: formData.country,
            isOrganization: `${formData.isOrganization}`,
            localBranch: formData.localBranch,
            gdprConsent: `${formData.gdprConsent}`,
            membershipType: formData.membershipType,
          }),
        });
        navigate("/bli-medlem/betala", { state: formData });
      } catch (err) {
        console.error("Kunde inte skicka formuläret", err);
        alert("Något gick fel. Försök igen senare.");
      }
    } else {
      console.log("Formuläret innehåller felaktiga uppgifter.");
    }
  };

  return (
    <>
      <Card
        sx={{ mb: 4, backgroundColor: "#f0f4c3", border: "1px solid #cddc39" }}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Är du redan medlem?
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Om du redan är medlem och vill betala medlemsavgiften, vänligen gå
            till sidan för betalning av medlemsavgift.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/medlemsavgift")}
          >
            Gå till betalning
          </Button>
        </CardContent>
      </Card>

      <Typography variant="h4" gutterBottom>
        Bli medlem!
      </Typography>

      <Box
        sx={{
          border: "1px solid #ddd",
          borderRadius: 2,
          padding: 2,
          backgroundColor: "#f9f9f9",
          mb: 3,
        }}
      >
        <FormControl component="fieldset" sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Välj medlemskap
          </Typography>
          <RadioGroup
            value={formData.membershipType}
            onChange={handleMembershipTypeChange}
          >
            {config().subscriptionTypes.map((type) => (
              <FormControlLabel
                key={type.id}
                value={type.id}
                control={<Radio />}
                label={`${type.description}: ${type.yearlyFee}:-${
                  type.priceComment ? ` (${type.priceComment})` : ""
                }`}
              />
            ))}
          </RadioGroup>
          {errors.membershipType && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              Du måste välja en medlemskapstyp.
            </Typography>
          )}
        </FormControl>
        <Typography variant="body2" gutterBottom>
          Prenumeration och medlemskap löper över kalenderår. Om du blir
          medlem/tecknar prenumeration under året så får du årets tidigare
          utgivna nummer omgående, och resten i den takt de kommer ut.
        </Typography>
        <Typography variant="body2" gutterBottom>
          Medlemskap lösta efter 1 oktober gäller för nästa kalenderår. Då får
          man sista numret av Odlaren innevarande år samt hela nästa år.
        </Typography>
      </Box>

      {formData.membershipType && <></>}
      <TextField
        fullWidth
        label="Namn"
        value={formData.name}
        onChange={handleInputChange("name")}
        onBlur={() => handleBlur("name")}
        error={errors.name}
        helperText={errors.name && "Namn är obligatoriskt."}
        sx={{ mb: 3 }}
      />

      {getSubscriptionType(formData.membershipType)?.paperSubscriptionOnly && (
        <FormControl component="fieldset" sx={{ mb: 3 }}>
          <RadioGroup
            value={formData.isOrganization ? "organization" : "private"}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="private"
              control={<Radio />}
              label="Privatperson"
            />
            <FormControlLabel
              value="organization"
              control={<Radio />}
              label="Företag/organisation"
            />
          </RadioGroup>
        </FormControl>
      )}

      <TextField
        fullWidth
        label="Gatuadress"
        value={formData.address}
        onChange={handleInputChange("address")}
        onBlur={() => handleBlur("address")}
        error={errors.address}
        helperText={errors.address && "Gatuadress är obligatoriskt."}
        sx={{ mb: 3 }}
      />

      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Postnummer"
            value={formData.postalCode}
            onChange={handleInputChange("postalCode")}
            onBlur={() => handleBlur("postalCode")}
            error={errors.postalCode}
            helperText={
              errors.postalCode &&
              (getSubscriptionType(formData.membershipType)?.foreign
                ? ""
                : "Postnummer måste vara i formatet 123 45.")
            }
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Postort"
            value={formData.city}
            onChange={handleInputChange("city")}
            onBlur={() => handleBlur("city")}
            error={errors.city}
            helperText={
              errors.city &&
              (getSubscriptionType(formData.membershipType)?.foreign
                ? ""
                : "Postort är obligatoriskt.")
            }
          />
        </Grid>
      </Grid>

      {getSubscriptionType(formData.membershipType)?.foreign && (
        <LandSelect
          value={formData.country}
          onChange={(e) =>
            setFormData({ ...formData, country: e.target.value })
          }
          error={errors.country}
        />
      )}

      <TextField
        fullWidth
        label="E-postadress"
        value={formData.email}
        onChange={handleInputChange("email")}
        onBlur={() => handleBlur("email")}
        error={errors.email}
        helperText={errors.email && "Ange en giltig e-postadress."}
        sx={{ mb: 3 }}
      />

      {!getSubscriptionType(formData.membershipType)?.paperSubscriptionOnly && (
        <>
          <Typography variant="body2" gutterBottom>
            Vi har inte lokalavdelningar överallt, men du kan välja en om du
            vill bli medlem i en specifik lokalavdelning. Det är helt
            frivilligt.
          </Typography>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <Select
              value={formData.localBranch}
              onChange={(e) =>
                setFormData({ ...formData, localBranch: e.target.value })
              }
              displayEmpty
            >
              <MenuItem key="" value="">
                Välj en lokalavdelning (valfritt)
              </MenuItem>
              {config().localBranches.map((branch) => (
                <MenuItem key={branch.id} value={branch.id}>
                  {branch.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}

      <Box
        sx={{
          border: "1px solid #ddd",
          borderRadius: 2,
          padding: 2,
          backgroundColor: "#f9f9f9",
          mb: 3,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.gdprConsent}
              onChange={handleConsentChange}
              onBlur={() => handleBlur("gdprConsent")}
              color="primary"
            />
          }
          label={
            <span>
              Jag samtycker till att mina personuppgifter behandlas enligt{" "}
              <Link
                component="button"
                onClick={handleOpenPrivacyPolicyDialog}
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  background: "none",
                  border: "none",
                  padding: 0,
                  cursor: "pointer",
                }}
              >
                FOBOs integritetspolicy
              </Link>
              .
            </span>
          }
        />
        {errors.gdprConsent && (
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            Integritetspolicyn handlar om hur vi hanterar dina personuppgifter
            och du måste godkänna den för att kunna använda detta formulär.
          </Typography>
        )}
      </Box>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={!formData.gdprConsent}
        onClick={handleSubmit}
      >
        Skicka ansökan
      </Button>
    </>
  );
};

export default MembershipApplicationPage;
