import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Slider,
  Typography,
} from "@mui/material";
import { TextField, Button } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useEditorContext } from "../EditorContext";
import { Block } from "./blockType";
import { imageBlockDataSchema, titleBlockDataSchema } from "./blockDataSchema";
import { z } from "zod";
import { loremIpsum } from "../utils/loremipsum";
import ZoomableImage from "../components/ZoomableImage";
import FileUploadButton from "../components/FileUploadButton";
import ImgEdit from "../components/ImgEdit";
import EditTitle from "../components/EditTitle";
import Img from "../components/Img";

const id = "image" as const;
const name = "Image" as const;

type ImageDataBlock = z.infer<typeof imageBlockDataSchema>;

const isDataBlock = (block: unknown): block is ImageDataBlock =>
  imageBlockDataSchema.safeParse(block).success;

function mapAspectRatio(size?: string) {
  if (!size) return 1;
  const [numerator, denominator] = size.split(":").map(parseFloat);
  return numerator / denominator;
}

const aspectRatios = ["32:9", "1:1", "16:9"];
const Component: React.FC<ImageDataBlock> = (block) => {
  const { editorFocus } = useEditorContext();
  const [beingEdited, setBeingEdited] = useState(false);
  useEffect(() => {
    setBeingEdited(!!(editorFocus && editorFocus === block.blockId));
  }, [block.blockId, setBeingEdited, editorFocus]);

  return (
    <Img
      imageHash={block.image}
      aspectRatio={mapAspectRatio(block.aspectRatio)}
      beingEdited={beingEdited}
      zoom={block.zoom}
      focalX={block.focalX}
      focalY={block.focalY}
      sx={{
        borderRadius: "8px",
        border: "13x solid black",
      }}
    />
  );
};

const ListComponent: React.FC<ImageDataBlock> = (block) => {
  return (
    <>
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <strong>Image</strong>
        <Box
          sx={{
            display: "inline-block",
            verticalAlign: "middle",
            marginLeft: "0.5em",
            height: "1.5em",
            width: "1.5em",
            backgroundImage: `url(https://2d.uix.se/image/${block.image}.webp)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </Box>
    </>
  );
};

const EditComponent: React.FC<ImageDataBlock> = (block) => {
  const { setWorkInProgress } = useEditorContext();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Sätt fokus på elementet när komponenten renderas
    if (inputRef.current) {
      // Pajjar pagineringen i renderingen i SlideView.
      // Kanske testa med att senarelägga detta?
      //inputRef.current.focus();
      //inputRef.current.select();
    }
  }, []); // Tom array gör att detta bara körs vid den första renderingen

  return (
    <>
      <EditTitle>Image</EditTitle>

      <Box sx={{ marginBottom: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="dropdown-label">Proportion</InputLabel>
          <Select
            labelId="dropdown-label"
            value={block.aspectRatio}
            onChange={(e: SelectChangeEvent<string>) => {
              const value = e.target.value;
              console.log("value", value);
              if (!value) return;
              if (aspectRatios.indexOf(value) !== -1) {
                console.log("setWorkInProgress", {
                  ...block,
                  aspectRatio: value as any, // We actually do check this...
                });
                setWorkInProgress({
                  ...block,
                  aspectRatio: value as any, // We actually do check this...
                });
              }
            }}
            label="Choose block type"
            sx={{
              background: "white",
              width: "100%",
            }}
          >
            <MenuItem key="32:9" value="32:9">
              32:9
            </MenuItem>
            <MenuItem key="16:9" value="16:9">
              16:9
            </MenuItem>
            <MenuItem key="1:!" value="1:1">
              1:1
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      <ImgEdit
        image={block.image}
        focalX={block.focalX}
        focalY={block.focalY}
        zoom={block.zoom}
        aspectRatio={mapAspectRatio(block.aspectRatio)}
        onFocalChange={(focalX, focalY) => {
          console.log("onFocalChange", { ...block, focalX, focalY });
          setWorkInProgress({ ...block, focalX, focalY });
        }}
        onZoomChange={(zoom) => setWorkInProgress({ ...block, zoom })}
        onNewImage={(image) => setWorkInProgress({ ...block, image })}
      />
    </>
  );
};

const initialBlock: () => Omit<
  ImageDataBlock,
  "blockId" | "endpoint"
> = () => ({
  blockType: "image" as const,
  image: "f0cf10816968f1cf5865057e2233697119ce3be49d8ae530063bb4a44e2a50e5",
  zoom: 100,
  focalX: 0.5,
  focalY: 0.5,
  aspectRatio: "16:9",
  size: "medium",
});

const block: Block<ImageDataBlock> = {
  id,
  name,
  isDataBlock: isDataBlock,
  Component,
  ListComponent,
  EditComponent,
  initialBlock,
};

export default block;
