import React from "react";
import { useSelector, useDispatch } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import { RootState } from "../store";
import { selectIsAuthenticated } from "../store/authSlice";
import { openDialog } from "../store/dialogSlice";
import { Link } from "react-router-dom";
import { isAdmin } from "../utils/auth";

const NavbarMenuItem: React.FC<{
  to: string;
  text: string;
  onClick: () => void;
}> = ({ to, text, onClick }) => {
  return (
    <Link
      to={to}
      style={{
        textDecoration: "none",
        color: "inherit",
        display: "block", // Gör länken lika bred som sitt innehåll
      }}
      onClick={onClick} // Stäng menyn vid navigering
    >
      <MenuItem>{text}</MenuItem>
    </Link>
  );
};

const Navbar: React.FC = () => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const dispatch = useDispatch();

  // Hämta inloggningsstatus och e-postadress från Redux
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const email = useSelector((state: RootState) => state.auth.email);

  // Hamburgermeny
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  // Profilmeny
  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {};

  // Öppna login-dialog
  const handleOpenLoginDialog = () => {
    console.log("HALLÅ!");
    handleMenuClose();
    dispatch(openDialog({ dialog: "login" }));
  };

  const handleOpenLogoutDialog = () => {
    handleMenuClose();
    dispatch(openDialog({ dialog: "logout" }));
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "#004d25" }}>
      <Toolbar>
        {/* Vänster: Titel */}
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            whiteSpace: "nowrap", // Förhindra radbrytning
            overflow: "hidden", // Klipp innehållet
            textOverflow: "ellipsis", // Visa "..." om texten är för lång
          }}
        >
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            <img
              src="/fobo.png"
              alt="Fobo"
              style={{
                height: "55px",
                margin: 0,
                padding: 0,
                position: "absolute",
                top: 1,
              }}
            />
            <img
              src="/fobotxt.png"
              alt="Fobo"
              style={{
                height: "25px",
                margin: 0,
                padding: 0,
                position: "absolute",
                top: 15,
                paddingLeft: 67,
              }}
            />
          </Link>
        </Typography>

        {/* Höger: E-post och avatar om inloggad, annars login-knapp */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {isAuthenticated ? (
            <>
              {/* E-post och avatar */}
              <Typography variant="body1">{email}</Typography>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="profile"
                onClick={handleProfileClick}
              >
                <AccountCircle />
              </IconButton>
            </>
          ) : (
            <Button
              color="inherit"
              onClick={handleOpenLoginDialog}
              sx={{
                whiteSpace: "nowrap", // Förhindra radbrytning
              }}
            >
              Logga in
            </Button>
          )}

          {/* Hamburgermeny */}
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <NavbarMenuItem
              to="/"
              text="Förstasidan"
              onClick={handleMenuClose}
            />

            <NavbarMenuItem
              to="/bli-medlem"
              text="Bli medlem"
              onClick={handleMenuClose}
            />

            <NavbarMenuItem
              to="/omfobo"
              text="Om FOBO"
              onClick={handleMenuClose}
            />

            {isAuthenticated && (
              <MenuItem onClick={handleOpenLogoutDialog}>Logga ut</MenuItem>
            )}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
