import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../store";
import { isAdmin } from "../utils/auth";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  CircularProgress,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

const AdminDashboard: React.FC = () => {
  const email = useSelector((state: RootState) => state.auth.email);
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Admin
      </Typography>

      <Grid container spacing={3}>
        {/* Kort för att hantera användare */}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Medlemshantering
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Importera medlemmar.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={() => navigate("/admin/medlemmar")}
              >
                Gå till medlemmar
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminDashboard;
