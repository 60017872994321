type LocalBranch = {
  id: string;
  label: string;
  csvLabels: string[];
};

type SubscriptionType = {
  id: string;
  label: string;
  yearlyFee: number;
  priceComment?: string;
  description: string;
  foreign?: boolean;
  paperSubscriptionOnly?: boolean;
  csvLabels: string[];
};

type Config = {
  localBranches: LocalBranch[];
  subscriptionTypes: SubscriptionType[];
};

export const config = () => {
  const config: Config = {
    subscriptionTypes: [
      {
        id: "enskild",
        label: "Enskild medlem",
        yearlyFee: 250,
        description: "Medlemsavgift för enskild medlem inklusive Odlaren",
        csvLabels: ["Enskild medlem"],
      },
      {
        id: "familj",
        label: "Familjemedlemskap",
        yearlyFee: 290,
        description: "Medlemsavgift för familj inklusive Odlaren",
        csvLabels: ["Familjemedlemskap"],
      },
      {
        id: "prenumeration",
        label: "Prenumeration",
        yearlyFee: 275,
        description: "Enbart prenumeration av Odlaren",
        paperSubscriptionOnly: true,
        csvLabels: ["Prenumerant via förmedling", "Gratis prenumerant"],
      },
      {
        id: "utlandsprenumeration",
        label: "Utlandsprenumeration",
        yearlyFee: 350,
        priceComment: "högre porto",
        description: "Utlandsprenumeration",
        foreign: true,
        paperSubscriptionOnly: true,
        csvLabels: ["Alien"],
      },
    ],
    localBranches: [
      { id: "eksjonassjo", label: "Eksjö-Nässjö", csvLabels: ["Eksjö-Nässjö"] },
      { id: "linkoping", label: "Linköping", csvLabels: ["Linköping"] },
      { id: "norrkoping", label: "Norrköping", csvLabels: ["Norrköping"] },
      { id: "skane", label: "Skåne", csvLabels: ["Skåne"] },
      { id: "stockholm", label: "Stockholm", csvLabels: ["Stockholm"] },
      { id: "sundsvall", label: "Sundsvall", csvLabels: ["Sundsvall"] },
    ],
  };
  return config;
};

export const getSubscriptionType = (id: string) => {
  const { subscriptionTypes } = config();
  return subscriptionTypes.find((type) => type.id === id);
};
