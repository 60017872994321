import React, { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, Typography } from "@mui/material";
import { on } from "events";

type RichTextEditorProps = {
  value: string;
  sx?: React.CSSProperties;
  onChange: (value: string) => void;
  height: number;
};

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value,
  sx,
  onChange,
  height,
}) => {
  const modules = {
    toolbar: [["bold", "italic", "strike", "link"]],
  };

  const formats = ["bold", "italic", "strike", "link"];

  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      <ReactQuill
        theme="snow"
        value={value}
        onChange={(v) => {
          console.log(v, value);
          v !== value && onChange(v);
        }}
        modules={modules}
        formats={formats}
        style={{ height: `${height - 43}px` }}
      />
    </Box>
  );
};

export default RichTextEditor;
