import { Box, Typography } from "@mui/material";

type EditTitleProps = {
  subtitle?: boolean;
  children: React.ReactNode;
};

const EditTitle: React.FC<EditTitleProps> = ({ subtitle, children }) => {
  return (
    <Box sx={{ padding: "1em 0em 0.5em 0em" }}>
      <Box
        sx={{
          borderTop: subtitle ? "1px solid #999" : "3px solid #000",
          margin: "0em",
        }}
      >
        <Typography
          variant={subtitle ? "h6" : "h5"}
          textTransform={subtitle ? "none" : "uppercase"}
          color={subtitle ? "text.secondary" : "text.primary"}
          component="h1"
          fontWeight={600}
          gutterBottom
          sx={{ padding: "0em 0em" }}
        >
          {children}
        </Typography>
      </Box>
    </Box>
  );
};

export default EditTitle;
