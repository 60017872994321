import Navbar from "./components/Navbar";
import { Provider } from "react-redux";
import store from "./store";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginDialog from "./components/LoginDialog";
import ConfirmDialog from "./components/ConfirmDialog";

import TokenHandler from "./components/TokenHandler";
import JwtHandler from "./components/JwtHandler";
import LogoutDialog from "./components/LogoutDialog";
import Admin from "./pages/Admin";
import MembershipApplicationPage from "./pages/MembershipApplication";
import MembershipConfirmationPage from "./pages/MembershipConfirmation";
import PrivacyPolicyDialog from "./components/PrivacyPolicyDialog";
import MembershipFeePage from "./pages/MembershipFeePage";
import { Box } from "@mui/material";
import MembersImportConfirmationDialog from "./components/MembersImportConfirmationDialog";
import { ContentProvider, useContent } from "./ContentContext";
import Content from "./components/Content";
import { EditorProvider } from "./EditorContext";
import ContentPages from "./pages/ContentPages";
import ContentEditor from "./components/ContentEditor";
import CoverAllOverlay from "./components/CoverAllOverlay";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import OverlayWithHole from "./components/OverlayWithHole";
import MetaData from "./components/MetaData";
import Footer from "./components/Footer";

const theme = createTheme({
  palette: {
    background: {
      default: "#FAF8F5", // Bakgrundsfärg för hela appen
    },
  },
});

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/admin/*" element={<Admin />} />
      <Route path="/bli-medlem" element={<MembershipApplicationPage />} />
      <Route path="/medlemsavgift" element={<MembershipFeePage />} />
      <Route
        path="/bli-medlem/betala"
        element={<MembershipConfirmationPage />}
      />
      <Route path="*" element={<ContentPages />} />
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ContentProvider>
          <MetaData />
          <EditorProvider>
            <CoverAllOverlay />
            <OverlayWithHole />
            <Provider store={store}>
              <TokenHandler />
              <JwtHandler />

              <ContentEditor>
                <Navbar />
                <main>
                  <Box sx={{ maxWidth: 600, margin: "0 auto", padding: 1 }}>
                    <AppRoutes />
                  </Box>
                </main>
                <Footer />
              </ContentEditor>

              <LoginDialog />
              <LogoutDialog />
              <ConfirmDialog />
              <PrivacyPolicyDialog />
            </Provider>
          </EditorProvider>
        </ContentProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
