import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type dialog =
  | "login"
  | "confirm"
  | "logout"
  | "privacypolicy"
  | "membersimport";

interface DialogState {
  activeDialog: dialog | null;
  dialogData?: any;
}

const initialState: DialogState = {
  activeDialog: null,
  dialogData: undefined,
};

const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    openDialog: (
      state,
      action: PayloadAction<{ dialog: dialog; data?: any }>
    ) => {
      console.log("openDialog", action.payload);
      state.activeDialog = action.payload.dialog;
      state.dialogData = action.payload.data;
    },
    closeDialog: (state) => {
      state.activeDialog = null;
      state.dialogData = undefined;
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
