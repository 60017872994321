import { z } from "zod";

export const baseBlockDataSchema = z.object({
  blockId: z.string(),
  endpoint: z.string(),
});

export const breadcrumbBlockDataSchema = baseBlockDataSchema.extend({
  blockType: z.literal("breadcrumb"),
});

export const titleBlockDataSchema = baseBlockDataSchema.extend({
  blockType: z.literal("title"),
  title: z.string(),
  subtitle: z.boolean().optional(),
});

export const quoteBlockDataSchema = baseBlockDataSchema.extend({
  blockType: z.literal("quote"),
  quote: z.string(),
  source: z.string(),
});

export const bodyTextBlockDataSchema = baseBlockDataSchema.extend({
  blockType: z.literal("bodyText"),
  bodyText: z.string(),
});

export const ingressBlockDataSchema = baseBlockDataSchema.extend({
  blockType: z.literal("ingress"),
  ingress: z.string(),
});

export const ctaBlockDataSchema = baseBlockDataSchema.extend({
  blockType: z.literal("cta"),
  copy: z.string(),
  buttonCopy: z.string(),
  link: z.string(),
  image: z.string(),
  zoom: z.number().min(-2000).max(2000),
  focalX: z.number().min(0).max(1),
  focalY: z.number().min(0).max(1),
});

export const teaserBlockDataSchema = baseBlockDataSchema.extend({
  blockType: z.literal("teaser"),
  copy: z.string(),
  link: z.string(),
  image: z.string(),
  zoom: z.number().min(-2000).max(2000),
  focalX: z.number().min(0).max(1),
  focalY: z.number().min(0).max(1),
});

export const imageBlockDataSchema = baseBlockDataSchema.extend({
  blockType: z.literal("image"),
  image: z.string(),
  aspectRatio: z.enum(["32:9", "16:9", "1:1"]).optional(),
  zoom: z.number().min(-2000).max(2000),
  focalX: z.number().min(0).max(1),
  focalY: z.number().min(0).max(1),
});

export const youtubeBlockDataSchema = baseBlockDataSchema.extend({
  blockType: z.literal("youtube"),
  url: z.string(),
});

export const extlinkBlockDataSchema = baseBlockDataSchema.extend({
  blockType: z.literal("extlink"),
  url: z.string(),
  small: z.boolean().optional(),
});

export const productBlockDataSchema = baseBlockDataSchema.extend({
  blockType: z.literal("product"),
  image: z.string(),
  title: z.string(),
  description: z.string(),
});

export const blockDataSchema = z.discriminatedUnion("blockType", [
  breadcrumbBlockDataSchema,
  titleBlockDataSchema,
  ingressBlockDataSchema,
  bodyTextBlockDataSchema,
  ctaBlockDataSchema,
  imageBlockDataSchema,
  youtubeBlockDataSchema,
  teaserBlockDataSchema,
  quoteBlockDataSchema,
  extlinkBlockDataSchema,
  productBlockDataSchema,
]);
