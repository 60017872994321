import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Content from "../components/Content";
import { BlockData } from "../blocks/blockType";
import { useContent } from "../ContentContext";
import { Box } from "@mui/material";
import { useEditorContext } from "../EditorContext";

const ContentPages = () => {
  const { blocks, isLoading, is404 } = useContent();

  const {
    contentClickAlert,
    setSlideAnimation,
    setEditorSlideIndex,
    isOpen,
    setIsOpen,
  } = useEditorContext();

  if (isLoading) return <div>Loading...</div>;

  return Array.isArray(blocks) && blocks.length === 0 ? (
    <Box
      sx={{ cursor: "pointer", textAlign: "center", padding: 2 }}
      onClick={
        is404
          ? () => {
              if (contentClickAlert()) return;
              if (isOpen) {
                setSlideAnimation(true);
                setEditorSlideIndex(0);
              } else {
                setSlideAnimation(false);
                setEditorSlideIndex(0);
                setIsOpen(true);
              }
            }
          : () => {
              if (contentClickAlert()) return;
              if (isOpen) {
                setSlideAnimation(true);
                setEditorSlideIndex(1);
              } else {
                setSlideAnimation(false);
                setEditorSlideIndex(1);
                setIsOpen(true);
              }
            }
      }
    >
      {is404 ? "404 - Page not found" : "Nothing here, yet."}
    </Box>
  ) : (
    <Content blocks={blocks as BlockData[]} />
  );
};

export default ContentPages;
