import { Helmet, HelmetProvider } from "react-helmet-async";
import { useContent } from "../ContentContext";

const MetaData: React.FC = () => {
  const { title } = useContent();

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Admin" />
      </Helmet>
    </HelmetProvider>
  );
};

export default MetaData;
