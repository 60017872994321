import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ListItem } from "@mui/material";
import { useEditorContext } from "../EditorContext";

interface SortableItemProps {
  id: string;
  children: React.ReactNode;
  highlightContent?: () => void;
  unhighlightContent?: () => void;
  scrollToContent?: () => void;
  highlighted?: boolean;
}

const SortableItem: React.FC<SortableItemProps> = ({
  id,
  children,
  highlightContent,
  unhighlightContent,
  scrollToContent,
  highlighted,
}) => {
  const myElement = React.useRef<HTMLElement | null>(null);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const { setBlockListHighlight, mouseInEditor } = useEditorContext();

  const setBothNodeRefs = (node: any) => {
    setNodeRef(node);
    myElement.current = node;
  };

  const [clickPos, setClickPos] = useState({ x: 0, y: 0 });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    marginBottom: "9px",
    backgroundColor: highlighted ? "rgba(190, 102, 102, 0.2)" : "#f0f0f0",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
    borderRadius: "6 px",
  };

  const onMouseDown = (e: any) => {
    if (unhighlightContent) unhighlightContent();
    setClickPos({ x: e.clientX, y: e.clientY });
  };

  const onMouseUp = (e: any) => {
    const x = e.clientX;
    const y = e.clientY;
    if (Math.abs(clickPos.x - x) < 6 && Math.abs(clickPos.y - y) < 6) {
      if (highlightContent) highlightContent();
      if (scrollToContent) scrollToContent();
    } else if (unhighlightContent) {
      unhighlightContent();
    }
  };

  const onMouseOver = (e: any) => {
    if (highlightContent) highlightContent();
    setBlockListHighlight(id);
  };

  function isInViewport(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  useEffect(() => {
    if (highlighted) {
      if (myElement.current) {
        if (!isInViewport(myElement.current) && !mouseInEditor) {
          myElement.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }
    // We don't want to be reactive on mouseInEditor here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlighted]);

  return (
    <ListItem
      id={`blocklist-${id}`}
      ref={setBothNodeRefs}
      {...attributes}
      {...listeners}
      style={style}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      onMouseLeave={unhighlightContent}
      onMouseOver={onMouseOver}
    >
      {children}
    </ListItem>
  );
};

export default SortableItem;
