export const deepEqual = (obj1: unknown, obj2: unknown) => {
  // Om det inte är objekt eller är null, gör en enkel jämförelse
  if (obj1 === obj2) return true;
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  // Jämför nycklar
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  // Rekursiv jämförelse av varje egenskap
  for (const key of keys1) {
    if (
      !obj2.hasOwnProperty(key) ||
      !deepEqual(
        (obj1 as Record<string, unknown>)[key],
        (obj2 as Record<string, unknown>)[key]
      )
    ) {
      return false;
    }
  }

  return true;
};
