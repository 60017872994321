import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { TextField, Button } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useEditorContext } from "../EditorContext";
import { Block } from "./blockType";
import { extlinkBlockDataSchema } from "./blockDataSchema";
import { z } from "zod";
import { loremIpsum } from "../utils/loremipsum";
import EditTitle from "../components/EditTitle";
import YouTubeEmbed from "../components/YouTubeEmbed";
import ZoomableImage from "../components/ZoomableImage";
import { Link } from "react-router-dom";

const id = "extlink" as const;
const name = "External Link" as const;

type ExtlinkDataBlock = z.infer<typeof extlinkBlockDataSchema>;

const isDataBlock = (block: unknown): block is ExtlinkDataBlock =>
  extlinkBlockDataSchema.safeParse(block).success;

const Component: React.FC<ExtlinkDataBlock> = ({ url, small }) => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [image, setImage] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = new URLSearchParams();
        params.set("url", url);
        const response = await fetch(
          `https://api.uix.se/api/og?${params.toString()}`
        );
        const data = await response.json();
        setTitle(data.title);
        setDescription(data.description);
        setImage(data.image);
      } catch (error) {
        console.error(error);
        setTitle("");
        setDescription("");
        setImage("");
      }
    };

    fetchData();
  }, [url]);

  if (small) {
    return (
      <Box sx={{}}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              aspectRatio: "16 / 9",
              background: "#fff",
              height: "5em",
              marginRight: "1em",
              borderRadius: "8px",
              border: "1px solid #ccc",
            }}
          >
            {image && (
              <ZoomableImage
                src={`https://2d.uix.se/image/${image}.webp`}
                aspectRatio={16 / 9}
                zoom={0}
                focalX={0.5}
                focalY={0.5}
              />
            )}
          </Box>
          <Box>
            <Link to={url} target="_blank" rel="noreferrer">
              <Typography variant="body1">
                {title || url || "No name"}
              </Typography>
            </Link>
            <Typography variant="body2" color="gray">
              {url}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        borderRadius: "8px",
        background: "#eee",
        padding: "1em",
        border: "1px solid #ccc",
      }}
    >
      <Box
        sx={{
          aspectRatio: "16 / 9",
          marginBottom: "0.5em",
          background: "#fff",
        }}
      >
        {image && (
          <ZoomableImage
            src={`https://2d.uix.se/image/${image}.webp`}
            aspectRatio={16 / 9}
            zoom={0}
            focalX={0.5}
            focalY={0.5}
          />
        )}
      </Box>
      <Link to={url} target="_blank" rel="noreferrer">
        <Typography variant="body1">{title || url || "No name"}</Typography>
      </Link>
      <Typography variant="body2">
        {description || title || url || "No description"}
      </Typography>
      <Typography variant="body2" color="gray">
        {url}
      </Typography>
    </Box>
  );
};

const ListComponent: React.FC<ExtlinkDataBlock> = (block) => {
  return (
    <>
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <strong>External Link</strong> {block.url}
      </Box>
    </>
  );
};

const EditComponent: React.FC<ExtlinkDataBlock> = (block) => {
  const { setWorkInProgress } = useEditorContext();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Sätt fokus på elementet när komponenten renderas
    if (inputRef.current) {
      // Pajjar pagineringen i renderingen i SlideView.
      // Kanske testa med att senarelägga detta?
      //inputRef.current.focus();
      //inputRef.current.select();
    }
  }, []); // Tom array gör att detta bara körs vid den första renderingen

  return (
    <>
      <EditTitle>External Link</EditTitle>

      <TextField
        label="External Link URL"
        variant="outlined"
        fullWidth
        value={block.url}
        onChange={(e) => setWorkInProgress({ ...block, url: e.target.value })}
        required
        sx={{ marginBottom: 2, backgroundColor: "white" }}
        inputRef={inputRef}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={block.small}
            onChange={(e) =>
              setWorkInProgress({
                ...block,
                small: e.target.checked ? true : false,
              })
            }
          />
        }
        label="Small"
      />
    </>
  );
};

const initialBlock: () => Omit<
  ExtlinkDataBlock,
  "blockId" | "endpoint"
> = () => ({
  blockType: "extlink" as const,
  url: "",
});

const block: Block<ExtlinkDataBlock> = {
  id,
  name,
  isDataBlock: isDataBlock,
  Component,
  ListComponent,
  EditComponent,
  initialBlock,
};

export default block;
