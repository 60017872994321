import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Slider,
  Typography,
} from "@mui/material";
import { TextField, Button } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useEditorContext } from "../EditorContext";
import { Block } from "./blockType";
import { ctaBlockDataSchema } from "./blockDataSchema";
import { z } from "zod";
import { loremIpsum } from "../utils/loremipsum";
import ZoomableImage from "../components/ZoomableImage";
import FileUploadButton from "../components/FileUploadButton";
import ImgEdit from "../components/ImgEdit";
import EditTitle from "../components/EditTitle";
import ResponsiveText from "../components/ResponsiveText";
import { useContent } from "../ContentContext";
import { Link } from "react-router-dom";
import Img from "../components/Img";

const id = "cta" as const;
const name = "Call to Action" as const;

const aspectRatio = 16 / 9;

type CtaDataBlock = z.infer<typeof ctaBlockDataSchema>;

const isDataBlock = (block: unknown): block is CtaDataBlock =>
  ctaBlockDataSchema.safeParse(block).success;

const Component: React.FC<CtaDataBlock> = (block) => {
  const { editorFocus, contentClicksDisabled } = useEditorContext();
  const [beingEdited, setBeingEdited] = useState(false);

  useEffect(() => {
    if (editorFocus && editorFocus === block.blockId) {
      setBeingEdited(true);
    } else {
      setBeingEdited(false);
    }
  }, [block.blockId, setBeingEdited, editorFocus]);

  return (
    <Box
      sx={{
        padding: "0em 0em 1em 0em",
        margin: "0em 0em 0em 0em",
      }}
    >
      <Box
        sx={{
          width: "100%",
          aspectRatio,
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            aspectRatio,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Img
            imageHash={block.image}
            aspectRatio={aspectRatio}
            zoom={block.zoom}
            focalX={block.focalX}
            focalY={block.focalY}
            beingEdited={beingEdited}
            borderRadius={8}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            aspectRatio,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: "hidden",
            textAlign: "center",
            fontWeight: "boldest",
            color: "white",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                overflow: "hidden",
                wrap: "nowrap",
                padding: "2em 2em 0em 2em",
              }}
            >
              <ResponsiveText copy={block.copy} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "1em",
              }}
            >
              <Box>
                <Link
                  to={block.link}
                  style={{ textDecoration: "none" }}
                  onClick={(e) => {
                    if (beingEdited || contentClicksDisabled) {
                      e.preventDefault();
                    }
                  }}
                >
                  <Button
                    disabled={!block.link}
                    variant="contained"
                    size="large"
                    sx={{
                      color: "white",
                      borderRadius: "9999px",
                      padding: "0.5em 1em",
                      fontWeight: "bold",
                      fontSize: "1.5em",
                      textTransform: "none",
                      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                    }}
                  >
                    {block.buttonCopy}
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const ListComponent: React.FC<CtaDataBlock> = (block) => {
  return (
    <>
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <strong>Call to Action</strong>
        <Box
          sx={{
            display: "inline-block",
            verticalAlign: "middle",
            marginLeft: "0.5em",
            height: "1.5em",
            width: "1.5em",
            backgroundImage: `url(https://2d.uix.se/image/${block.image}.webp)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </Box>
    </>
  );
};

const EditComponent: React.FC<CtaDataBlock> = (block) => {
  const {
    endpoints,
    setEndpoints,
    addNewEndpoint,
    changeEndpoint,
    deletePage,
  } = useContent(); // Använd context för endpoints och setEndpoints

  const { setWorkInProgress } = useEditorContext();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Sätt fokus på elementet när komponenten renderas
    if (inputRef.current) {
      // Pajjar pagineringen i renderingen i SlideView.
      // Kanske testa med att senarelägga detta?
      //inputRef.current.focus();
      //inputRef.current.select();
    }
  }, []); // Tom array gör att detta bara körs vid den första renderingen

  return (
    <>
      <EditTitle>Call to Action</EditTitle>

      <TextField
        label="Copy"
        variant="outlined"
        fullWidth
        value={block.copy}
        onChange={(e) => setWorkInProgress({ ...block, copy: e.target.value })}
        required
        sx={{ marginBottom: 2, backgroundColor: "white" }}
        inputRef={inputRef}
      />

      <ImgEdit
        image={block.image}
        focalX={block.focalX}
        focalY={block.focalY}
        zoom={block.zoom}
        aspectRatio={aspectRatio}
        onFocalChange={(focalX, focalY) => {
          console.log("onFocalChange", { ...block, focalX, focalY });
          setWorkInProgress({ ...block, focalX, focalY });
        }}
        onZoomChange={(zoom) => setWorkInProgress({ ...block, zoom })}
        onNewImage={(image) => setWorkInProgress({ ...block, image })}
      />

      <EditTitle subtitle={true}>Button</EditTitle>

      <TextField
        label="Button copy"
        variant="outlined"
        fullWidth
        value={block.buttonCopy}
        onChange={(e) =>
          setWorkInProgress({ ...block, buttonCopy: e.target.value })
        }
        required
        sx={{ marginBottom: 2, backgroundColor: "white" }}
        inputRef={inputRef}
      />

      <Box sx={{ marginBottom: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="dropdown-label">Link</InputLabel>
          <Select
            labelId="dropdown-label"
            value={block.link}
            onChange={(e: SelectChangeEvent<string>) => {
              const value = e.target.value;
              setWorkInProgress({
                ...block,
                link: value,
              });
            }}
            label="Link"
            sx={{
              background: "white",
              width: "100%",
            }}
          >
            {endpoints.map((endpoint) => (
              <MenuItem key={endpoint} value={endpoint}>
                {endpoint}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

const initialBlock: () => Omit<CtaDataBlock, "blockId" | "endpoint"> = () => ({
  blockType: "cta" as const,
  link: "",
  copy: `${loremIpsum(3)}!`,
  buttonCopy: "Click me!",
  image: "f0cf10816968f1cf5865057e2233697119ce3be49d8ae530063bb4a44e2a50e5",
  zoom: 100,
  focalX: 0.5,
  focalY: 0.5,
});

const block: Block<CtaDataBlock> = {
  id,
  name,
  isDataBlock: isDataBlock,
  Component,
  ListComponent,
  EditComponent,
  initialBlock,
};

export default block;
