import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { closeDialog, openDialog } from "../store/dialogSlice";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

const LoginDialog: React.FC = () => {
  const dispatch = useDispatch();

  const activeDialog = useSelector(
    (state: RootState) => state.dialog.activeDialog
  );

  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setEmail("");
    setValidationMessage("");
    setLoading(false);
    dispatch(closeDialog());
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async () => {
    if (!validateEmail(email)) {
      setIsValid(false);
      setValidationMessage("Ange en giltig e-postadress.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch("https://loginmail.se/api/loginmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          returnEndpoint: "/",
        }),
      });

      if (response.ok) {
        dispatch(openDialog({ dialog: "confirm" })); // Öppna confirm-dialogen
      } else {
        throw new Error("Något gick fel. Försök igen.");
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message); // Logga det faktiska felet
      } else {
        console.error("Okänt fel inträffade", error); // Hantera om det inte är en Error-instans
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    if (validateEmail(value)) {
      setIsValid(true);
      setValidationMessage("");
    } else if (value.trim() !== "") {
      setIsValid(false);
      setValidationMessage("Ange en giltig e-postadress.");
    } else {
      setIsValid(true);
      setValidationMessage("");
    }
  };

  return (
    <Dialog open={activeDialog === "login"} onClose={handleClose}>
      <DialogTitle>Logga in</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Endast betalande medlemmar i föreningen kan logga in.
        </DialogContentText>
        <DialogContentText>
          Ange din e-postadress nedan. Du kommer att få en länk skickad till dig
          för att logga in.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="E-postadress"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => handleEmailChange(e.target.value)}
          error={!isValid}
          helperText={validationMessage}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" disabled={loading}>
          Avbryt
        </Button>
        <Button
          onClick={handleLogin}
          color="primary"
          disabled={!email.trim() || loading}
          startIcon={
            loading ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          Skicka länk
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginDialog;
