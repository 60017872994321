import React, { useMemo, useState } from "react";

import { useEditorContext } from "../EditorContext";
import { Box } from "@mui/material";

const CoverAllOverlay: React.FC = () => {
  const { coverActive } = useEditorContext();

  return (
    <>
      {coverActive && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 9999, // Ligger över allt
            cursor: "grabbing", // Visar "grabbing"-pekare
          }}
        />
      )}
    </>
  );
};

export default CoverAllOverlay;
