import { Box } from "@mui/material";
import blockTypes from "../blocks";
import { useEditorContext } from "../EditorContext";
import React, { useMemo } from "react";
import {
  BlockData,
  isBlockData,
  WrapperComponentProps,
} from "../blocks/blockType";
import { isAdmin } from "../utils/auth";
import { RootState } from "../store";
import { useSelector } from "react-redux";

type ContentProps = {
  blocks: Array<BlockData & { blockId: string; endpoint: string }>;
};

const Content: React.FC<ContentProps> = ({ blocks }) => {
  const {
    editorFocus,
    setEditorFocus,
    isOpen,
    setIsOpen,
    workInProgress,
    contentClickAlert,
    setWorkInProgress,
    setEditorSlideIndex,
    setSlideAnimation,
    setBlockListHighlight,
  } = useEditorContext();

  const email = useSelector((state: RootState) => state.auth.email);

  const workInProgressBlocks = useMemo(() => {
    return blocks.map((block) =>
      isBlockData(workInProgress) && workInProgress.blockId === block.blockId
        ? workInProgress
        : block
    );
  }, [blocks, workInProgress]);

  const groupedBlocks = useMemo(() => {
    const groupedBlocks = [];
    let currentGroup: typeof workInProgressBlocks = [];
    for (const block of workInProgressBlocks) {
      if (currentGroup.length === 0) {
        currentGroup.push(block);
        continue;
      } else if (currentGroup.at(-1)!.blockType === block.blockType) {
        currentGroup.push(block);
      } else {
        groupedBlocks.push(currentGroup);
        currentGroup = [block];
      }
    }
    if (currentGroup.length > 0) {
      groupedBlocks.push(currentGroup);
    }
    return groupedBlocks;
  }, [workInProgressBlocks]);

  return (
    <>
      {groupedBlocks.map((group, index) => {
        const blockType = blockTypes.find(({ isDataBlock }) =>
          isDataBlock(group[0] as any)
        );
        if (!blockType) return <></>;
        const Wrapper =
          "WrapperComponent" in blockType &&
          blockType.WrapperComponent !== undefined
            ? blockType.WrapperComponent
            : React.Fragment;
        const { Component } = blockType;
        return (
          <Wrapper key={index}>
            {group.map((block, index) => (
              <Box
                id={`block-${block.blockId}`}
                sx={{
                  padding: 1,
                }}
                onClick={(e) => {
                  if (!isAdmin(email)) return;

                  if (contentClickAlert()) return;
                  if (editorFocus !== block.blockId) {
                    setEditorFocus(block.blockId);
                    setWorkInProgress(null);
                  }
                  if (!isOpen) {
                    setSlideAnimation(false);
                    setEditorSlideIndex(2);
                    setIsOpen(true);
                  } else {
                    setSlideAnimation(true);
                    setEditorSlideIndex(2);
                  }
                }}
                onMouseEnter={() => {
                  if (!isAdmin(email)) return;

                  setBlockListHighlight(block.blockId);
                }}
                key={index}
              >
                <Component {...(block as any)} />
              </Box>
            ))}
          </Wrapper>
        );
      })}
    </>
  );
};

export default Content;
