import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { TextField, Button } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useEditorContext } from "../EditorContext";
import { Block } from "./blockType";
import { youtubeBlockDataSchema } from "./blockDataSchema";
import { z } from "zod";
import { loremIpsum } from "../utils/loremipsum";
import EditTitle from "../components/EditTitle";
import YouTubeEmbed from "../components/YouTubeEmbed";

const id = "youtube" as const;
const name = "YouTube" as const;

type YoutubeDataBlock = z.infer<typeof youtubeBlockDataSchema>;

const isDataBlock = (block: unknown): block is YoutubeDataBlock =>
  youtubeBlockDataSchema.safeParse(block).success;

const Component: React.FC<YoutubeDataBlock> = (block) => {
  return (
    <Box sx={{}}>
      <YouTubeEmbed url={block.url} />
    </Box>
  );
};

const ListComponent: React.FC<YoutubeDataBlock> = (block) => {
  return (
    <>
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <strong>YouTube</strong> {block.url}
      </Box>
    </>
  );
};

const EditComponent: React.FC<YoutubeDataBlock> = (block) => {
  const { setWorkInProgress } = useEditorContext();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Sätt fokus på elementet när komponenten renderas
    if (inputRef.current) {
      // Pajjar pagineringen i renderingen i SlideView.
      // Kanske testa med att senarelägga detta?
      //inputRef.current.focus();
      //inputRef.current.select();
    }
  }, []); // Tom array gör att detta bara körs vid den första renderingen

  return (
    <>
      <EditTitle>YouTube</EditTitle>

      <TextField
        label="YouTube URL"
        variant="outlined"
        fullWidth
        value={block.url}
        onChange={(e) => setWorkInProgress({ ...block, url: e.target.value })}
        required
        sx={{ marginBottom: 2, backgroundColor: "white" }}
        inputRef={inputRef}
      />
    </>
  );
};

const initialBlock: () => Omit<
  YoutubeDataBlock,
  "blockId" | "endpoint"
> = () => ({
  blockType: "youtube" as const,
  url: "https://www.youtube.com/watch?v=oHg5SJYRHA0",
});

const block: Block<YoutubeDataBlock> = {
  id,
  name,
  isDataBlock: isDataBlock,
  Component,
  ListComponent,
  EditComponent,
  initialBlock,
};

export default block;
