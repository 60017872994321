import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const TokenHandler: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleToken = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      if (token) {
        try {
          // Validera att det är en giltig JWT (valfritt)
          const base64Url = token.split(".")[1]; // Payload i JWT
          const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          JSON.parse(atob(base64)); // Parse för att säkerställa att det är giltig JSON

          // Spara token som en cookie
          Cookies.set("jwt", token, { expires: 7, secure: true });

          // Ta bort `token` från URL:en
          urlParams.delete("token");
          navigate(`${window.location.pathname}?${urlParams.toString()}`, {
            replace: true,
          });
        } catch (error) {
          console.error("Ogiltig JWT:", error);
        }
      }
    };

    handleToken();
  }, [navigate]);

  return null;
};

export default TokenHandler;
