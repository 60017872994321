import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { TextField, Button } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useEditorContext } from "../EditorContext";
import { Block } from "./blockType";
import { productBlockDataSchema } from "./blockDataSchema";
import { z } from "zod";
import { loremIpsum } from "../utils/loremipsum";
import EditTitle from "../components/EditTitle";
import { Label } from "@mui/icons-material";
import FileUploadButton from "../components/FileUploadButton";
import RichTextEditor from "../components/RichTextEditor";
import HtmlContent from "../components/HtmlContent";

const id = "product" as const;
const name = "Product" as const;

type ProductDataBlock = z.infer<typeof productBlockDataSchema>;

const isDataBlock = (block: unknown): block is ProductDataBlock =>
  productBlockDataSchema.safeParse(block).success;

const Component: React.FC<ProductDataBlock> = (block) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          flex: 6,
        }}
      >
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: block.title.trim() ? "black" : "red",
              fontWeight: "bolder",
              fontSize: "1.2em",
            }}
          >
            {block.title.trim() || "- No title -"}
          </Typography>
        </Box>
        <Box>
          <HtmlContent html={block.description} sx={{ lineHeight: 1.6 }} />
        </Box>
      </Box>
      <Box sx={{ flex: 4 }}>
        <img src={`https://2d.uix.se/image/${block.image}.webp`} width="100%" />
      </Box>
    </Box>
  );
};

const ListComponent: React.FC<ProductDataBlock> = (block) => {
  return (
    <>
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <strong>Product</strong> {block.title}
      </Box>
    </>
  );
};

const EditComponent: React.FC<ProductDataBlock> = (block) => {
  const { setWorkInProgress } = useEditorContext();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Sätt fokus på elementet när komponenten renderas
    if (inputRef.current) {
      // Pajjar pagineringen i renderingen i SlideView.
      // Kanske testa med att senarelägga detta?
      //inputRef.current.focus();
      //inputRef.current.select();
    }
  }, []); // Tom array gör att detta bara körs vid den första renderingen

  return (
    <>
      <EditTitle>Product</EditTitle>

      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        value={block.title}
        onChange={(e) => setWorkInProgress({ ...block, title: e.target.value })}
        required
        sx={{ marginBottom: 2, backgroundColor: "white" }}
        inputRef={inputRef}
      />

      <RichTextEditor
        value={block.description}
        sx={{ height: "350px", backgroundColor: "white" }}
        height={350}
        onChange={(value) =>
          setWorkInProgress({ ...block, description: value })
        }
      />

      <EditTitle subtitle={true}>Image</EditTitle>

      <FileUploadButton
        onUpload={(sha256) => setWorkInProgress({ ...block, image: sha256 })}
      />
    </>
  );
};

const initialBlock: () => Omit<
  ProductDataBlock,
  "blockId" | "endpoint"
> = () => ({
  blockType: "product" as const,
  title: loremIpsum(Math.floor(Math.random() * 1) + 3),
  description: loremIpsum(Math.floor(Math.random() * 10) + 3),
  image: "f0cf10816968f1cf5865057e2233697119ce3be49d8ae530063bb4a44e2a50e5",
});

const block: Block<ProductDataBlock> = {
  id,
  name,
  isDataBlock: isDataBlock,
  Component,
  ListComponent,
  EditComponent,
  initialBlock,
};

export default block;
