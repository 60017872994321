import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import Dropzone from "../components/Dropzone";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Papa from "papaparse";

import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import SectionHeader from "../components/SectionHeader";
import { hashEmail, sha256 } from "../utils/hash";
import { config } from "../utils/config";
import { getOverlappingValues } from "../utils/array";
import { openDialog } from "../store/dialogSlice";
import MembersImportConfirmationDialog from "../components/MembersImportConfirmationDialog";

type Member = {
  id: string;
  email: string | null;
  localBranch: string | null;
  subscriptionType: string | null;
};

const AdminMembers: React.FC = () => {
  const email = useSelector((state: RootState) => state.auth.email);
  const navigate = useNavigate();
  const [importedMembers, setImportedMembers] = useState<Array<Member>>([]);

  const dispatch = useDispatch();

  const onFileContentLoaded = (content: string | null) => {
    if (content) {
      Papa.parse(content, {
        header: true,
        skipEmptyLines: true,
        complete: async (results: { data: Array<object> }) => {
          const members = await Promise.all(
            results.data.map(async (item) => {
              const email =
                "EmailAddress" in item &&
                typeof item["EmailAddress"] === "string"
                  ? item["EmailAddress"]
                  : null;
              const customerNumber =
                "CustomerNumber" in item &&
                typeof item["CustomerNumber"] === "string"
                  ? item["CustomerNumber"]
                  : null;
              const customerLabels =
                "CustomerLabels" in item &&
                typeof item["CustomerLabels"] === "string"
                  ? item["CustomerLabels"]
                  : null;
              const isActive =
                "IsActive" in item && typeof item["IsActive"] === "string"
                  ? item["IsActive"]
                  : null;

              const customerLabelArr =
                customerLabels === null ? [] : customerLabels.split(";");
              const localBranch = config().localBranches.find((branch) => {
                if (
                  getOverlappingValues(customerLabelArr, branch.csvLabels)
                    .length > 0
                ) {
                  return true;
                }
              })?.id;
              const subscriptionType = config().subscriptionTypes.find(
                (subscription) => {
                  if (
                    getOverlappingValues(
                      customerLabelArr,
                      subscription.csvLabels
                    ).length > 0
                  ) {
                    return true;
                  }
                }
              )?.id;

              const result: Member = {
                id: customerNumber || "",
                email: await hashEmail(email),
                localBranch: localBranch || null,
                subscriptionType: subscriptionType || null,
              };

              return result;
            })
          );
          setImportedMembers(members);

          dispatch(openDialog({ dialog: "membersimport" }));
        },
      });
    }
  };

  const onFileAdded = (file: File | null) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const content = reader.result as string; // Filens innehåll som sträng
        onFileContentLoaded(content);
      };
      reader.onerror = () => {
        console.error("Kunde inte läsa filen");
        onFileContentLoaded(null);
      };
      reader.readAsText(file); // Läser filen som text
    } else {
      onFileContentLoaded(null);
    }
  };

  return (
    <>
      <PageHeader>
        <Link to="/admin">Admin</Link> / Medlemmar
      </PageHeader>
      <SectionHeader>Importera medlemsregistret</SectionHeader>
      <Dropzone onFileAdded={onFileAdded} accept="text/csv" />
      <MembersImportConfirmationDialog>
        Följande har importerats. Dessa har lagts till i databasen utöver dem
        som redan fanns där. Dubletter har skrivits över.
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Typ</TableCell>
                <TableCell>Antal importerade</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {config().localBranches.map((branch) => {
                return (
                  <TableRow>
                    <TableCell>{branch.label}</TableCell>
                    <TableCell>
                      {
                        importedMembers.filter(
                          (member) => member.localBranch === branch.id
                        ).length
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
              {config().subscriptionTypes.map((type) => {
                return (
                  <TableRow>
                    <TableCell>{type.label}</TableCell>
                    <TableCell>
                      {
                        importedMembers.filter(
                          (member) => member.subscriptionType === type.id
                        ).length
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell style={{ whiteSpace: "nowrap" }}>Total</TableCell>
                <TableCell>{importedMembers.length}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </MembersImportConfirmationDialog>
    </>
  );
};

export default AdminMembers;
