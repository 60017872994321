import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  SetStateAction,
  Dispatch,
  useEffect,
} from "react";
import { BlockData } from "./blocks/blockType";
import { set } from "zod";

// Typen för våra värden
interface EditorContextType {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  editorWidth: number;
  editorFocus: string;
  setEditorWidth: (width: number) => void;
  setEditorFocus: (focus: string) => void;
  workInProgress: BlockData | null;
  setWorkInProgress: (block: BlockData | null) => void;
  contentClickAlert: () => boolean;
  contentClicksDisabled: boolean;
  setContentClicksDisabled: (disabled: boolean) => void;
  editorSlideIndex: number;
  setEditorSlideIndex: Dispatch<SetStateAction<number>>;
  slideAnimation: boolean;
  setSlideAnimation: (slideAnimation: boolean) => void;
  alert: Alerts;
  setAlert: (alert: Alerts) => void;
  deleteBlockPrompt: string | null;
  setDeleteBlockPrompt: (prompt: string | null) => void;
  coverActive: boolean;
  setCoverActive: Dispatch<SetStateAction<boolean>>;
  highlightedContent: HTMLElement | null;
  setHighlightedContent: (element: HTMLElement | null) => void;
  blockListHighlight: string;
  setBlockListHighlight: Dispatch<SetStateAction<string>>;
  mouseInEditor: boolean;
  mousePosition: { x: number; y: number };
}

// Skapa Context
const EditorContext = createContext<EditorContextType | undefined>(undefined);

type Alerts = "saveOrCancel" | null;

// Provider-komponent
export const EditorProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editorWidth, setEditorWidth] = useState<number>(400);
  const [editorFocus, setEditorFocus] = useState<string>("");
  const [editorSlideIndex, setEditorSlideIndex] = useState<number>(0);
  const [slideAnimation, setSlideAnimation] = useState(false); // Styr animation
  const [workInProgress, setWorkInProgress] = useState<BlockData | null>(null);
  const [contentClicksDisabled, setContentClicksDisabled] = useState(false);
  const [alert, setAlert] = useState<Alerts>(null);
  const [deleteBlockPrompt, setDeleteBlockPrompt] = useState<string | null>(
    null
  );
  const [coverActive, setCoverActive] = useState(false);
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [highlightedContent, setHighlightedContent] =
    useState<HTMLElement | null>(null);
  const [blockListHighlight, setBlockListHighlight] = useState<string>("");
  const [mouseInEditor, setMouseInEditor] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener("mousemove", handleMouseMove);
  }, []);

  useEffect(() => {
    if (mousePosition.x < editorWidth) {
      setMouseInEditor(true);
    } else {
      setMouseInEditor(false);
    }
  }, [mousePosition, editorWidth]);

  const contentClickAlert = () => {
    if (contentClicksDisabled) {
      setAlert("saveOrCancel");
      return true;
    }
    return false;
  };

  return (
    <EditorContext.Provider
      value={{
        isOpen,
        setIsOpen,
        editorWidth,
        editorFocus,
        setEditorWidth,
        setEditorFocus,
        workInProgress,
        setWorkInProgress: (block) => {
          console.log("setWorkInProgress", block);
          setWorkInProgress(block);
        },
        contentClickAlert,
        contentClicksDisabled,
        setContentClicksDisabled,
        editorSlideIndex,
        setEditorSlideIndex,
        slideAnimation,
        setSlideAnimation,
        alert,
        setAlert,
        deleteBlockPrompt,
        setDeleteBlockPrompt,
        coverActive,
        setCoverActive,
        highlightedContent,
        setHighlightedContent,
        blockListHighlight,
        setBlockListHighlight,
        mouseInEditor,
        mousePosition,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

// Custom Hook för att använda Contexten
export const useEditorContext = () => {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error("useEditorContext måste användas inom en EditorProvider");
  }
  return context;
};
