import { configureStore } from "@reduxjs/toolkit";
import dialogReducer from "./dialogSlice";
import authReducer from "./authSlice";

const store = configureStore({
  reducer: {
    dialog: dialogReducer,
    auth: authReducer, // Auth reducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
