import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Slider,
  Typography,
} from "@mui/material";
import { TextField, Button } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useEditorContext } from "../EditorContext";
import { Block, WrapperComponentProps } from "./blockType";
import { teaserBlockDataSchema } from "./blockDataSchema";
import { z } from "zod";
import { loremIpsum } from "../utils/loremipsum";
import ZoomableImage from "../components/ZoomableImage";
import FileUploadButton from "../components/FileUploadButton";
import ImgEdit from "../components/ImgEdit";
import EditTitle from "../components/EditTitle";
import ResponsiveText from "../components/ResponsiveText";
import { useContent } from "../ContentContext";
import { Link } from "react-router-dom";
import Img from "../components/Img";
import { isAdmin } from "../utils/auth";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const id = "teaser" as const;
const name = "Teaser" as const;

const aspectRatio = 1;

type TeaserDataBlock = z.infer<typeof teaserBlockDataSchema>;

const isDataBlock = (block: unknown): block is TeaserDataBlock =>
  teaserBlockDataSchema.safeParse(block).success;

const WrapperComponent: React.FC<WrapperComponentProps> = ({ children }) => (
  <Box
    sx={{
      background:
        "linear-gradient(180deg,rgb(216, 216, 216) 50px,rgba(255, 255, 255,0) 150px)",
      borderRadius: 8,
      padding: 1,

      containerType: "inline-size", // => CSS: container-type: inline-size
      containerName: "myBox", // => CSS: container-name: myBox

      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: 0,
      width: "100%",

      "@media (max-width: 450px)": {
        gridTemplateColumns: "repeat(2, 1fr)",
      },
    }}
  >
    {React.Children.toArray(children).map((child, index) => (
      <Box
        key={index}
        sx={{
          width: "100%",
          textAlign: "center",
        }}
      >
        {child}
      </Box>
    ))}
  </Box>
);

const Component: React.FC<TeaserDataBlock> = (block) => {
  const { editorFocus, contentClicksDisabled } = useEditorContext();
  const [beingEdited, setBeingEdited] = useState(false);

  const email = useSelector((state: RootState) => state.auth.email);

  useEffect(() => {
    if (editorFocus && editorFocus === block.blockId) {
      setBeingEdited(true);
    } else {
      setBeingEdited(false);
    }
  }, [block.blockId, setBeingEdited, editorFocus]);

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        overflow: "visible",
      }}
    >
      <Button
        component={Link}
        to={block.link}
        variant="text"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 0,
          width: "100%",
        }}
        onClick={(e) => {
          if (contentClicksDisabled || isAdmin(email)) {
            e.preventDefault();
            return;
          }
        }}
      >
        <Img
          imageHash={block.image}
          aspectRatio={aspectRatio}
          zoom={block.zoom}
          focalX={block.focalX}
          focalY={block.focalY}
          beingEdited={beingEdited}
          borderRadius={3}
          sx={
            {
              /*
            width: "calc(100% - 16px)",
            overflow: "visible",
            borderRadius: "8px",
            margin: "0 8px",
            boxShadow: "0 8px 8px rgba(0, 0, 0, 0.25)",
            */
            }
          }
        />
      </Button>

      <Link
        to={block.link}
        style={{ display: "block", textDecoration: "none", color: "inherit" }}
        onClick={(e) => {
          if (contentClicksDisabled) {
            e.preventDefault();
            return;
          }
          e.stopPropagation();
        }}
      >
        <Typography
          variant="body1"
          sx={{
            margin: "4px 0px 0px 0px",
            padding: 0,
            // Upper case
            textTransform: "uppercase",
          }}
        >
          {block.copy}
        </Typography>
      </Link>
    </Box>
  );
};

const ListComponent: React.FC<TeaserDataBlock> = (block) => {
  return (
    <>
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <strong>Teaser</strong> {block.copy}
        <Box
          sx={{
            display: "inline-block",
            verticalAlign: "middle",
            marginLeft: "0.5em",
            height: "1.5em",
            width: "1.5em",
            backgroundImage: `url(https://2d.uix.se/image/${block.image}.webp)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </Box>
    </>
  );
};

const EditComponent: React.FC<TeaserDataBlock> = (block) => {
  const { endpoints } = useContent(); // Använd context för endpoints och setEndpoints

  const { setWorkInProgress } = useEditorContext();

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <EditTitle>Teaser</EditTitle>

      <ImgEdit
        image={block.image}
        focalX={block.focalX}
        focalY={block.focalY}
        zoom={block.zoom}
        aspectRatio={aspectRatio}
        onFocalChange={(focalX, focalY) => {
          console.log("onFocalChange", { ...block, focalX, focalY });
          setWorkInProgress({ ...block, focalX, focalY });
        }}
        onZoomChange={(zoom) => setWorkInProgress({ ...block, zoom })}
        onNewImage={(image) => setWorkInProgress({ ...block, image })}
      />

      <TextField
        label="Copy"
        variant="outlined"
        fullWidth
        value={block.copy}
        onChange={(e) => setWorkInProgress({ ...block, copy: e.target.value })}
        required
        sx={{ marginBottom: 2, backgroundColor: "white" }}
        inputRef={inputRef}
      />

      <Box sx={{ marginBottom: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="dropdown-label">Link</InputLabel>
          <Select
            labelId="dropdown-label"
            value={block.link}
            onChange={(e: SelectChangeEvent<string>) => {
              const value = e.target.value;
              setWorkInProgress({
                ...block,
                link: value,
              });
            }}
            label="Link"
            sx={{
              background: "white",
              width: "100%",
            }}
          >
            {endpoints.map((endpoint) => (
              <MenuItem key={endpoint} value={endpoint}>
                {endpoint}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

const initialBlock: () => Omit<
  TeaserDataBlock,
  "blockId" | "endpoint"
> = () => ({
  blockType: "teaser" as const,
  link: "",
  copy: loremIpsum(3),
  image: "f0cf10816968f1cf5865057e2233697119ce3be49d8ae530063bb4a44e2a50e5",
  zoom: 100,
  focalX: 0.5,
  focalY: 0.5,
});

const block: Block<TeaserDataBlock> = {
  id,
  name,
  isDataBlock: isDataBlock,
  Component,
  ListComponent,
  EditComponent,
  initialBlock,
  WrapperComponent,
};

export default block;
